import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { setBlocker, clearBlocker } from '../../../shared/slice/blockerSlice'
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import DocumentsService, {
  viewAndPrintDocument,
} from "../../../common/api/documentsApi";
import ModalButton from "../../../shared/Ui/Buttons/ModalButtons";
import { EmptyListPlaceholderContent } from "../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../shared/Ui/ModalComponent/ModalWindow";
import Stepper from "../../../shared/components/Stepper/Stepper";
import Pagination from "../../../shared/components/Table/Pagination";
import { ITable } from "../../../shared/components/Table/types";
import { AddByOrderNumber } from "../Upload/AddByOrderNumber/AddByOrderNumber";
import { DocumentUpload } from "../Upload/DocumentUpload/DocumentUpload";
import DocumentsSearch from "../Upload/DocumentsSearch/DocumentsSearch";
import MarkScan from "../Upload/MarkScan/MarkScan";
import style from "./DocumentImportEAEU.module.css";
// @ts-ignore
import { createDocument } from "../../../common/api/notificationApi";
import AlertBox from "../../../shared/Ui/AlertBox/AlertBox";
import { useAppDispatch, useAppSelector } from "../../../shared/hook";
import { deleteDataDocument } from "../../../shared/slice/documentState";
import CheckResult from "../Upload/CheckResults/CheckResult";

import { format } from "date-fns";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";
import CheckBox from "../../../shared/Ui/CheckBox/CheckBox";
import Loader from "../../../shared/Ui/Loader/Loader";
import StatusBadge from "../../../shared/Ui/StatusBadge/StatusBadge";
import { submitXmlProduct } from "../../../shared/signature/documentsSignature";
import PageNotRole from "../../../shared/Ui/PageNotFound/PageNotRole";

function DocumentImportEAEUOrders() {
  const documentState = useAppSelector((state) => state.documentState);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);
  const navigate = useNavigate();
  const { importEaeuDocumentId } = useParams();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [tableData, setTableData] = useState<ITable>();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [loading, setLoading] = useState(true);
  const [errorModal, setErrorModal] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(null);
  const [deleteMarks, setDeleteMarks] = useState<number[]>([]);
  const [isOrderNumberModal, setIsOrderNumberModal] = useState<boolean>(false);
  const [isDocumentSearchModalOpen, setIsDocumentSearchModalOpen] =
    useState<boolean>(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const [isMarkScanModal, setIsMarkScanModal] = useState<boolean>(false);
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<any>("new");
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [statusType, setStatusType] = useState({
    type: "new",
    label: t("documentStatus.new"),
  });
  const [toggleOnLoad, setToggleOnLoad] = useState<boolean>(false);
  const userData = useAppSelector((state) => state.auth.userData);

  const [initialValues, setInitialValues] = useState<any>({
    refEaeuCountry: "",
    manufacturerName: "",
    customsDecisionDocNumber: "", // Регистрационный номер документа
    customsClearanceRegNumber: "", // Ти документа(справ.)
    customsClearanceRegAt: "", // Дата регистрации документа
    customsDecisionAt: "", // Дата решения
    protocolAt: "", // Дата протокола испытания
    protocolNumber: "", // Номер протокола испытания
    expertAt: "", // Дата экспертного заключения
    expertNumber: "", // Номер экспертного заключения
    controlActNumber: "", // Номер Акта государственного контроля
    controlActAt: "", // Дата Акта государственного контроля
    refCustomsDecisionCode: 0, // Код таможенного органа(справ.)
    docNumber: "", // Номер документа
    note: "", // Примечание
    senderBinEAEU: "",
    orgId: "",
    createUser: "",
    createdAt: "",
    // updatedAt: "",
    // signingAt: "",
    receiver: "",
    sender: "",
    status: "new",

    id: "", // ИД
    refDocType: 4,

    refCountryExport: "",
    documentPaperNumber: "", // Hoмер бум. документа
    documentPaperAt: "", //Дата бум. документа
    document_id: "", // ИД документа
  });

  // useEffect(() => {
  //   onLoad();
  //   if (documentState.isSafe) {
  //     setIsFormChanged(true);
  //   }
  // }, [
  //   pagination,
  //   isOrderNumberModal,
  //   isDocumentSearchModalOpen,
  //   isMarkScanModal,
  //   isCheckOpen,
  //   CheckResult,
  //   navigate,
  //   toggleOnLoad,
  // ]);
  useEffect(() => {
    const roleObj = () => {
      return {
        roleDoc: roleMatrix?.roleMatrix?.find(item => item.id === 4).specificAction.documents[2],
        roleDraft: roleMatrix?.roleMatrix?.find(item => item.id === 6),
        roleAccepted: roleMatrix?.roleMatrix?.find((item) => item.id === 4)
      }
    }

    setRole(roleObj)

    onLoad();
    if (documentState.isSafe) {
      dispatch(setBlocker(true))
      setIsFormChanged(true);
    }
  }, [
    pagination,
    isOrderNumberModal,
    isDocumentSearchModalOpen,
    isMarkScanModal,
    isCheckOpen,
    CheckResult,
    navigate,
    toggleOnLoad,
  ]);
  // async function onLoad() {
  //   setLoading(true)
  //   if (importEaeuDocumentId !== undefined) {
  //     await DocumentsService.getDocMarksList(
  //       importEaeuDocumentId,
  //       pagination?.pageIndex + 1,
  //       pagination.pageSize,
  //     )
  //       .then((response) => {
  //         console.log(response);
  //         if (response) {
  //           setTableData({
  //             values: response?.products,
  //             currentPage: response?.currentPage,
  //             itemsPerPage: response?.itemsPerPage,
  //             totalItems: response?.totalItems,
  //             totalPages: response?.totalPages,
  //           });
  //         }
  //         setLoading(false)
  //       })

  //       .catch((error) => {
  //         console.error("Error fetching marks from document:", error),
  //           setTableData({
  //             values: [],
  //             currentPage: 0,
  //             itemsPerPage: 0,
  //             totalItems: 0,
  //             totalPages: 0,
  //           });
  //           setLoading(false)
  //       });
  //     await DocumentsService.getDocument(importEaeuDocumentId)
  //       .then((documentResponse) => {
  //         const { type, label } = getStatusType(documentResponse.data.status);
  //         setStatusType({ type, label });
  //         setStatus(documentResponse.data.status);
  //         setInitialValues(documentResponse.data);
  //       })
  //       .catch((error) => {
  //         navigate("/404");
  //       });
  //   }else{
  //     setLoading(false)
  //   }
  // }
  async function onLoad() {
    setLoading(true); // Устанавливаем состояние загрузки в true

    if (importEaeuDocumentId !== undefined) {
      try {
        const response = await DocumentsService.getDocMarksList(
          importEaeuDocumentId,
          pagination?.pageIndex + 1,
          pagination.pageSize
        );

        console.log(response);

        if (response) {
          setTableData({
            values: response?.products,
            currentPage: response?.currentPage,
            itemsPerPage: response?.itemsPerPage,
            totalItems: response?.totalItems,
            totalPages: response?.totalPages,
          });
        }
      } catch (error) {
        console.error("Error fetching marks from document:", error);
        setTableData({
          values: [],
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        });
      }

      try {
        const documentResponse = await DocumentsService.getDocument(importEaeuDocumentId);
        const { type, label } = getStatusType(documentResponse.data.status);
        setStatusType({ type, label });
        setStatus(documentResponse.data.status);
        setInitialValues(documentResponse.data);
      } catch (error) {
        console.error("Ошибка при загрузке документа:", error);
        navigate("/404");
      }
    }

    setLoading(false); // Завершаем состояние загрузки в конце выполнения функции
  }


  async function submitCreate() {
    const backendData = {
      ...documentState.documentData,
      refDocType: 4,
      id: importEaeuDocumentId || null,
    };
    if (importEaeuDocumentId && documentState.isSafe) {
      await DocumentsService.editDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          onLoad();
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_edited_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_edit_error"),
          });
        });
    }
    if (!importEaeuDocumentId) {
      const create = await createDocument(backendData)
        .then((response) => {
          dispatch(clearBlocker())
          setIsFormChanged(false);
          dispatch(deleteDataDocument());
          navigate(
            `/documents/import-eaeu/${response.data.document.id}/orders`,
          );
          setAlertOpen({
            status: "SUCCESS",
            message: t("documentNotification.document_saved_success"),
          });
        })
        .catch((error) => {
          console.error("Error creating new invoice:", error);
          setAlertOpen({
            status: "error",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : t("documentNotification.document_save_error"),
          });
        });
    }
  }

  const getDocument = async () => {
    try {
      if (importEaeuDocumentId) {
        setIsLoading(true);
        const fileBlob =
          await DocumentsService.downloadDocumentMarks(importEaeuDocumentId);
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${initialValues?.docNumber}.xlsx`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Это ЗАПРОС НА ПОДПИСАНИЕ ЗАЯВКИ!
  // async function submitXmlProduct() {
  //   const ncalayerClient = new NCALayerClient();

  //   try {
  //     await ncalayerClient.connect();
  //   } catch (error) {
  //     setAlertOpen({
  //       status: "error",
  //       message: t("documentNotification.nca_no_connect"),
  //     });
  //     return;
  //   }

  //   let xml;
  //   try {
  //     setIsLoading(true);
  //     xml = await getDocumentXMLForSigning(importEaeuDocumentId);
  //     console.log("xml", xml);
  //     if (!xml || !xml.data) {
  //       console.log("Не удалось получить XML документ для подписи.");
  //       return;
  //     }
  //     if (xml.data.errorFields && xml.data.errorFields?.length > 0) {
  //       setErrorModal(xml.data.errorFields);
  //       console.log("xml1", xml.data.errorFields);
  //       return;
  //     }
  //     if (xml.data.problemMarks && xml.data.problemMarks?.length > 0) {
  //       setIsCheckOpen(xml.data.problemMarks);
  //       console.log("xml2", xml.data.problemMarks);
  //       return;
  //     }
  //   } catch (error: any) {
  //     console.log("errorka", error);
  //     setErrorModal({
  //       singleError: true,
  //       errorMessage: error.response?.data?.message
  //         ? error.response?.data?.message
  //         : t("documentNotification.unknown_error"),
  //     });
  //     return;
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   let xmlSignature;
  //   try {
  //     xmlSignature = await ncalayerClient.basicsSignXML(
  //       NCALayerClient.basicsStoragesAll,
  //       xml.data.xml,
  //       NCALayerClient.basicsXMLParams,
  //       process.env.REACT_APP_ETRACK_TEST
  //         ? NCALayerClient.basicsSignerTestAny
  //         : NCALayerClient.basicsSignerSignAny,
  //       "ru",
  //     );
  //     console.log("Подпись XML:", xmlSignature[0]);
  //   } catch (error: any) {
  //     setErrorModal(error.data.message);
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const documentIdValue = importEaeuDocumentId ?? "0";
  //     await saveSignedDocumentXML(documentIdValue, xmlSignature[0]);
  //     alert(t("documentNotification.document_xml_success"));
  //     navigate("/documents/inbox");
  //   } catch (error) {
  //     const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
  //     console.log("e", e);
  //     const errorMessage = e.response?.data;
  //     alert(t("documentNotification.document_xml_error"));
  //     console.log("errorka", errorMessage);
  //     setErrorModal({
  //       singleError: true,
  //       errorMessage: errorMessage?.message
  //         ? errorMessage?.message
  //         : t("documentNotification.unknown_error"),
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  // Это ЗАПРОС НА ФАЙЫ ДОКА!
  const handleDownloadClick = async () => {
    try {
      setIsLoading(true);
      const fileBlob = await viewAndPrintDocument(importEaeuDocumentId);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `document_${importEaeuDocumentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function getDescription(value: any) {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      default:
        return t("documentType.unknown");
    }
  }

  function getStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: t("documentStatus.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }

  const checkboxDelete = (id: any) => {
    setDeleteMarks((prevDeleteMarks: any) => {
      if (prevDeleteMarks.includes(id)) {
        return prevDeleteMarks.filter((item: any) => item !== id);
      } else {
        return [...prevDeleteMarks, id];
      }
    });
  };

  const fullDeleteMarks = () => {
    setDeleteMarks([]);
    if (tableData?.values) {
      const allIds = tableData.values.map((value: any) => value?.markId);
      setDeleteMarks(allIds);
    }
    if (deleteMarks?.length === tableData?.values?.length) {
      setDeleteMarks([]);
    }
  };

  const handleDelete = (docId?: string, marks?: string[]) => {
    if (typeof docId !== undefined && marks) {
      DocumentsService.deleteMarks(docId, marks)
        .then((response) => {
          if (tableData?.currentPage === tableData?.totalPages) {
            setPagination((prevState) => ({
              ...prevState,
              pageIndex:
                prevState.pageIndex === 0
                  ? prevState.pageIndex
                  : prevState.pageIndex - 1,
            }));
          }
          setDeleteMarks([]);
        })
        .catch((error) =>
          console.log(`Error deleting mark from document:" ${error}`),
        )
        .finally(() => {
          setIsDeleteModalOpen(null);
          onLoad();
        });
    } else {
      setErrorModal(t("documentNotification.no_id_error"));
      setIsDeleteModalOpen(null);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isFormChanged) {
        event.preventDefault();
        event.returnValue = t("documentNotification.unsaved_changes_warning");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormChanged]);
  const { isBlocked, proceed } = useAppSelector((state) => state.blocker);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked !== false && // и если форма изменена
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes("import-eaeu"),
  );

  if (loading && !role) return <Loader />;

  if (!role || !role?.roleAccepted?.view) {
    return (
      <PageNotRole />
    )
  }

  return (
    <div className="max-w-[1585px] w-full m-t-[20px]">
      {isBlocked && blocker.state === "blocked" ? (

        <ModalComponent
              isOpen={true} // Модальное окно всегда открыто, когда blocker.state === "blocked"
              onClose={() => {
                if (blocker && typeof blocker.reset === "function") {
                  blocker.reset();
                } else {
                  console.error("Reset function is not available.");
                }
              }}
              headerContent={
                <>
                  <span>{t("documentNotification.notification")}</span>
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(clearBlocker())
                      if (blocker && typeof blocker.reset === "function") {
                        blocker.reset();
                      } else {
                        console.error("Reset function is not available.");
                      }
                    }}
                    aria-label="Close"
                    className="float-right cursor-pointer bg-none border-none"
                  >
                    &times; {/* Символ крестика */}
                  </button>
                </>
              }
              content={<p>{t("documentNotification.unsaved_changes_warning")}</p>}
              customContent={
                <>
                 <ModalButton
        type="button"
        onClick={() => {
          
          dispatch(clearBlocker())
          if (blocker && typeof blocker.proceed === "function") {
            blocker.proceed(); // Уходим со страницы без сохранения
          } else {
            console.error("Proceed function is not available.");
          }
        }}
      >
        {t("mainButtons.cancel")}
      </ModalButton>
                  <ModalButton
                    type="button"
                    color="green"
                    onClick={async () => {
                      try {
                        await submitCreate(); // Сохранение данных
                        if (blocker && typeof blocker.proceed === "function") {
                          blocker.proceed(); // Переход после сохранения
                        }
                      } catch (error) {
                        console.error("Error saving data:", error);
                        // Можно обработать ошибку, например, показать уведомление пользователю
                      }
                    }}
                  >
                    {t("mainButtons.save")}
                  </ModalButton>
                </>
              }
            />
      ) : null}
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <div>
            {importEaeuDocumentId ? (
              <div className="flex gap-[20px]">
                <div className="flex flex-col">
                  <StatusBadge type={statusType.type}>
                    {t("documentLabels.status") + ": " + statusType.label}
                  </StatusBadge>
                  {(initialValues?.signingAt || initialValues?.createdAt) && (
                    <div className="text-[14px] font-semibold text-gray-700 ml-[9px]">
                      {format(
                        new Date(
                          initialValues?.signingAt || initialValues?.createdAt,
                        ),
                        "dd.MM.yyyy, HH:mm",
                      )}
                    </div>
                  )}
                </div>
                <span className="flex gap-[10px]">
                  <div>{getDescription(initialValues?.refDocType)}:</div>
                  <div>
                    {initialValues?.docNumber
                      ? initialValues?.docNumber
                      : " " + t("mainLabels.not_generated")}
                  </div>
                </span>
              </div>
            ) : (
              <div className="flex gap-[20px]">
                <StatusBadge type={statusType.type}>
                  {t("documentLabels.status") + " " + statusType.label}
                </StatusBadge>
                <div>
                  {t("documentLabels.create_document")}
                  {": "}
                  {t("documentType.notification_from_eaes")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-between ">
          <div className="my-auto">
            <Stepper currentStep={2} />
          </div>

          <div className="flex flex-row gap-[20px] flex-wrap">
            {/* Показываем кнопки только после завершения загрузки */}
            {!loading && (
              <>
                {/* Кнопка "Скачать марки" */}
                {/* {importEaeuDocumentId &&
      status === "PROCESSED" &&
      tableData?.totalItems &&
      tableData?.totalItems > 0 && (
        <button
          className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
          type="button"
          onClick={() => getDocument()}
        >
          {t("documentLabels_3.download_marks")}
        </button>
      )} */}
                {importEaeuDocumentId &&
                  status === "PROCESSED" &&
                  tableData?.totalItems &&
                  tableData.totalItems > 0 &&
                  role.roleDoc.actions[0].active ? (
                  <button
                    className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() => getDocument()}
                  >
                    {t("documentLabels_3.download_marks")}
                  </button>
                ) : null}


                {/* Кнопка "Закрыть" */}
                <button
                  className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={() => {
                    dispatch(deleteDataDocument());
                    if (initialValues.status == "DRAFT") {
                      console.log("Navigating to drafts");
                      navigate("/documents/drafts");
                  }else if (initialValues.status == "new") {
                    console.log("Navigating to sent");
                    navigate("/documents/inbox");
                  } 
                   else if (userData.iinBin !== initialValues.receiver) {
                      console.log("Navigating to sent");
                      navigate("/documents/sent");
                  } else {
                      console.log("Navigating to inbox");
                      navigate("/documents/inbox");
                  }
                    
                  }}
                >
                  {t("mainButtons.close")}
                </button>

                {/* Кнопка "Назад" */}
                <button
                  className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                  type="button"
                  onClick={() =>
                    importEaeuDocumentId
                      ? navigate(`/documents/import-eaeu/${importEaeuDocumentId}`)
                      : navigate(`/documents/import-eaeu/new`)
                  }
                >
                  {t("mainButtons.back")}
                </button>

                {/* Кнопка "Сохранить" */}
                {((status === "new" && role.roleDraft.edit) ||
                  (status == "DRAFT" && role.roleDraft.edit)) && (
                    <button
                      className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        submitCreate();
                      }}
                    >
                      {t("mainButtons.save")}
                    </button>
                  )}

                {/* Кнопка "Подписать" */}
                {((status === "IN_ACCEPTANCE" && role.roleDoc.actions[0].active) ||
                  (status == "DRAFT" && role.roleDraft.specificAction.actions[0].active)) && (
                    <button
                    className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                    type="button"
                    onClick={async () => {
                      try {
                        // Вызов функции внутри try блока
                        await submitXmlProduct(
                          importEaeuDocumentId,
                          navigate,
                          setAlertOpen,
                          setIsLoading,
                          setErrorModal,
                          setIsCheckOpen,
                          t,
                        );
                      } catch (error) {
                        console.error("Error during submitXmlProduct:", error);
                        setErrorModal(error);
                      } finally {
                        // Действия, которые всегда выполняются, например, сброс загрузки
                        setIsLoading(false);
                      }
                    }}
                  >
                    {t("mainButtons.sign")}
                  </button>
                  
                  )}

                {/* Кнопка "Скачать" */}
                {status !== "DRAFT" &&
                  status !== "new" &&
                  status !== "IN_ACCEPTANCE" &&
                  role.roleDoc.actions[1].active && (
                    <div className="flex flex-row gap-[20px] flex-wrap">
                      <button
                        className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded buttonEtrackc"
                        type="button"
                        onClick={() => handleDownloadClick()}
                      // disabled={tableData?.values?.length === 0 || formik.values.status !== "new" && formik.values.status !== "DRAFT"}
                      >
                        {t("mainButtons.print")}
                      </button>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col bg-white  my-3 px-5 pb-3 relative rounded-xl shadow-md  gap-[20px]">
          <div className=" my-5 relative flex flex-row align-center gap-x-[20px]">
            {((status === "new" && role.roleDraft.edit) ||
              (status == "DRAFT" && role.roleDraft.edit)) && (
                <>
                  <button
                    onClick={() => {
                      dispatch(deleteDataDocument());
                      submitCreate();
                    }}
                    className=" bg-green text-white font-semibold py-2 px-4 rounded"
                    data-tooltip-offset={4}
                    data-tooltip-id="document-type"
                  >
                    {t("documents_orders.add_products")}
                  </button>
                  {deleteMarks?.length > 0 && (
                    <button
                      onClick={() => setIsDeleteModalOpen(deleteMarks)}
                      className=" bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                    >
                      {t("documents_orders.delete_products")}
                    </button>
                  )}
                  <div className="z-[1000] relative	">
                    <Tooltip
                      id="document-type"
                      className={classNames(
                        style.wrapperTooltip,
                        "drop-shadow-2xl",
                      )}
                      openOnClick={true}
                      clickable={true}
                      noArrow={true}
                      opacity={1}
                      place={"bottom-end"}
                    >
                      <div
                        className={classNames(
                          style.tooltip,
                          "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
                        )}
                      >
                        <button
                          type="button"
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsFileUploadModalOpen(true)}
                        >
                          <span>{t("documents_orders.upload_from_file")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsDocumentSearchModalOpen(true)}
                        >
                          <span>{t("documents_orders.add_codes")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsMarkScanModal(true)}
                        >
                          <span>{t("mark_scan.scan_codes")}</span>
                        </button>
                        <button
                          className={classNames(
                            "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                          )}
                          onClick={() => setIsOrderNumberModal(true)}
                        >
                          <span>{t("documents_orders.by_order_number")}</span>
                        </button>
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
            {/* <div className="flex flex-row gap-[10px]">
              <button
                onClick={() =>
                  setPagination((prevValues: PaginationState) => {
                    return { ...prevValues, pageIndex: 0 };
                  })
                }
              >
                <img src="/icons/update.svg"></img>
              </button>
            </div> */}
          </div>
          <div className="w-full">
            <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    {(status === "new" ||
                      (status === "DRAFT" && tableData?.totalItems !== 0)) && (
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          <CheckBox
                            checked={
                              deleteMarks?.length === tableData?.values?.length
                            }
                            onChange={() => {
                              fullDeleteMarks();
                            }}
                          />
                        </th>
                      )}
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      № {t("documents_orders.number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.ntin")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.order_number")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_orders.product_name")}
                    </th>
                    {/* <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Наименование изготовителя
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Тип упаковки
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Артикул
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Тип изделия
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Наименование драгоценного металла
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      Проба
                    </th> */}
                  </tr>
                </thead>
                {tableData?.values?.length === 0 || !tableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_orders.no_data_title")}
                            description={t(
                              "documents_orders.no_data_description",
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {tableData?.values?.map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value.id}`}>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <CheckBox
                                id="myCheckbox"
                                checked={deleteMarks.includes(value?.markId)}
                                onChange={() => checkboxDelete(value?.markId)}
                              />
                            </td>
                          )}
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index + 1 || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.markCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productTypeCode || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.orderNumber || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value?.productName || "-"}
                        </td>
                        {((status === "new" && role.roleDraft.edit) ||
                          (status == "DRAFT" && role.roleDraft.edit)) && (
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <img
                                src="/icons/trash.svg"
                                className="cursor-pointer min-w-[20px]"
                                onClick={() =>
                                  setIsDeleteModalOpen([value?.markId])
                                }
                              ></img>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            {deleteMarks.length>0 
                    ?
                    <div className="pt-5 px-5 text-gray-600 font-medium">
                    {t('productPersonalization.selected')} {deleteMarks.length} из {tableData?.totalItems}
                        </div>
                    :
                    <div className="pt-5 px-5 text-gray-600 font-medium">
                    {t('productPersonalization.selected')} 0 из {tableData?.totalItems}
                    </div>
                    }
            <Pagination
              totalItems={tableData?.totalItems || 0}
              totalPages={tableData?.totalPages || 0}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
          <ModalComponent
            isOpen={!!isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <p className="p__modal">{t("documents_orders.confirm_delete")}</p>
            }
            customContent={
              <div className="flex flex-row gap-[10px]">
                <ModalButton
                  type="button"
                  color="grey"
                  onClick={() => setIsDeleteModalOpen(null)}
                >
                  {t("mainButtons.cancel")}
                </ModalButton>
                <ModalButton
                  // color="green"
                  type="button"
                  onClick={() => {
                    isDeleteModalOpen &&
                      handleDelete(importEaeuDocumentId, isDeleteModalOpen);
                    setIsDeleteModalOpen(null);
                    onLoad();
                  }}
                >
                  {t("mainButtons.delete")}
                </ModalButton>
              </div>
            }
          />
          <ModalComponent
            isOpen={errorModal && !errorModal.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")} -{" "}
                {t("documentNotification.check_fields")}:
              </h1>
            }
            content={
              <div className="error-list">
                <ul>
                  {errorModal && errorModal?.length > 0 ? (
                    errorModal.map((error: any, index: any) => (
                      <li className="border-b border-gray-300 py-1" key={index}>
                        {t(`error_dictionary.${error}`)}
                      </li>
                    ))
                  ) : (
                    <li>{t("documentNotification.no_errors")}</li>
                  )}
                </ul>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />
          <ModalComponent
            isOpen={errorModal?.singleError}
            onClose={() => setErrorModal(null)}
            headerContent={
              <h1 className="h__modal">
                {t("documentNotification.notification")}
              </h1>
            }
            content={
              <div className="error-list">
                <p className="border-b border-gray-300 py-1">
                  {t("documentStatus.error")}: {errorModal?.errorMessage}
                </p>
              </div>
            }
            customContent={
              <ModalButton
                type="button"
                onClick={() => {
                  setErrorModal(null);
                }}
              >
                {t("mainButtons.accept")}
              </ModalButton>
            }
          />

          {isOrderNumberModal && (
            <AddByOrderNumber
              isOpen={isOrderNumberModal}
              setIsOpen={setIsOrderNumberModal}
              documentId={importEaeuDocumentId}
              toggleOnLoad={toggleOnLoad}
              setToggleOnLoad={setToggleOnLoad}
              setErrorModal={setErrorModal}
            />
          )}
          <DocumentsSearch
            isOpen={isDocumentSearchModalOpen}
            setIsOpen={setIsDocumentSearchModalOpen}
            documentId={importEaeuDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <DocumentUpload
            isOpen={isFileUploadModalOpen}
            setIsOpen={setIsFileUploadModalOpen}
            documentId={importEaeuDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <MarkScan
            isOpen={isMarkScanModal}
            setIsOpen={setIsMarkScanModal}
            documentId={importEaeuDocumentId}
            toggleOnLoad={toggleOnLoad}
            setToggleOnLoad={setToggleOnLoad}
          />
          <CheckResult
            isOpen={isCheckOpen}
            setIsOpen={setIsCheckOpen}
            problemMarks={isCheckOpen}
            docId={importEaeuDocumentId}
          />
          <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default DocumentImportEAEUOrders;
