import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../shared/hook";
import { downloadUserFiles, userFiles } from "../../common/api/authApi";
import { format } from "date-fns";
import { FaInfoCircle } from "react-icons/fa";
// import {styles} from "./ProfileCard.module.css"
import style from "./ProfileCard.module.css";

function ProfileCard() {
  const { t, i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState<any>(null);
  const [userFilesData, setUserFilesData] = useState([]);
  const userData: any = useAppSelector((state) => state.auth.userData);
  const [formData, setFormData] = useState({
    checkingAccount: "KZ78956UII8888899000",
    bic: [
      { value: "role1", text: "Опция А" },
      { value: "role2", text: "Опция Б" },
      { value: "role3", text: "Опция В" },
    ],
    email: "ivanov.ivan@gmail.com",
    website: "www.aasd.kz",
    role: [
      { value: "role1", text: "Импортер" },
      { value: "role2", text: "Роль 2" },
      { value: "role3", text: "Роль 3" },
    ],
    phone: "+7 (123) 456-78-90",
  });

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const result = await userFiles();
        setUserFilesData(result.data);
      } catch (error) {
        console.error("Error fetching user files:", error);
      }
    };

    fetchUserFiles();
  }, []);

  // Это ЗАПРОС НА ФАЙЫ ДОКА DOWLOAD!
  const handleDownloadClick = async (filename: any,index:any) => {
    try {
      // setIsLoading(true);
      const fileBlob = await downloadUserFiles(filename);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${index === 0
        ? "Заявление о присоединении"
        : "Договор доступа к услугам"}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // освободите ресурсы, связанные с URL
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    }
  };
  console.log(213, userData);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => (
        <div className="w-full flex flex-col gap-[40px] font-['Inter']">
          <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
            <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="assets/contactData.png" className="w-[20px]" />
              <div className="uppercase">{t("profile_card.contacts")}</div>
            </div>
            <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.name")}
                <p className="font-light">
                  {userData.userName} {userData.userSurname}{" "}
                  {userData.userPatronymic}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.iin")}
                <p className=" font-light">{userData.userIIN}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.phone")}
                <p className="text-gray-600 font-light">{userData.phone}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.email")}
                <p className="text-gray-600 font-light">{userData.email}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.postion")}
                <p className="text-gray-600 font-light">{userData.userPosition}</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
            <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="assets/orgData.png" className="w-[20px]" />
              <div className="uppercase">
                {t("profile_card.organization_title")}
              </div>
            </div>
            <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_name")}
                <p className="text-gray-600 font-light">
                  {userData.organizationName}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_iin_bin")}

                <p className="text-gray-600 font-light">{userData.iinBin}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_address")}

                <p className="text-gray-600 font-light">
    {`${userData.address.city || ""}${userData.address.city ? "," : ""} 
    ${userData.address.street || ""}${userData.address.street ? "," : ""} 
    ${userData.address.house || ""} 
    ${userData.address.building ?   userData.address.building : ""}
    ${userData.address.premises ?   userData.address.premises : ""}`}
  </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_website")}

                <p className="font-light">
                  {userData.organizationWebsite
                    ? userData.organizationWebsite
                    : "-"}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_phone")}

                <p className="font-light">{userData.organizationPhone}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_email")}

                <p className="font-light">{userData.organizationEmail}</p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.code_ogd")}
                {userData.address.refOgd?.id &&
                userData.address.refOgd?.name ? (
                  <p className="font-light">           
                    {userData.address.refOgd?.name}
                  </p>
                ) : (
                  <p className="font-light">-</p>
                )}
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px] max-h-[230px]">
            <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="assets/docs.png" className="w-[20px]" />
              <div className="uppercase">
                {t("profile_card.participation_in_the_system")}
              </div>
            </div>
            
            <div className="flex gap-x-[100px] gap-y-[20px]  text-base flex-wrap">
            <div className="flex flex-col gap-[20px]">
            <div className="text-gray-600 w-[300px] font-semibold">
      <div className={style.tooltipWrapper}>
        {t('profile_card.registration_date')}
        <FaInfoCircle className={style.errorIcon} />
        <div className={style.tooltipText}>
          {t('profile_card.registration_date_tooltip')} 
        </div>
      </div>
      {userData.createdAt ? (
        <p className="text-gray-600 font-light">
          {format(new Date(userData?.createdAt), 'dd.MM.yyyy')}
        </p>
      ) : (
        '-'
      )}
      
    </div>
    <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.organization_product_group")}

                <p className="font-light">
                  {userData.refProductGroup.name
                    ? userData.refProductGroup.name
                    : "-"}
                </p>
              </div>
              </div>
              <div className="text-gray-600 max-w-[600px] w-full font-semibold max-h-[100px]">
  {t("profile_card.role")}
  
  <div className="text-gray-600 font-light grid grid-cols-2 gap-4">
    {userData?.selectRoleV2?.length > 0 ? (
      userData.selectRoleV2.slice(0, 4).map((role: any, index: any) => (
        <p key={index} className="block">
          {role.nameRu}
        </p>
      ))
    ) : (
      <p>-</p>
    )}
  </div>
</div>
              
            </div>
          </div>

          <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px]">
            <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="assets/docs.png" className="w-[20px]" />
              <div className="uppercase">{t("profile_card.bank_title")}</div>
            </div>
            <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.bank_name")}
                <p className="text-gray-600 font-light">
                  {userData.refBankCatalog.name
                    ? userData.refBankCatalog.name
                    : "-"}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.bank_identification_code")}
                <p className="text-gray-600 font-light">
                  {userData.bankIdentificationCode}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.bank_checking_account")}
                <p className="text-gray-600 font-light">
                  {userData.checkingAccount}
                </p>
              </div>
              <div className="text-gray-600 w-[300px] font-semibold">
                {t("profile_card.bank_kbe")}
                <p className="font-light">
                  {userData.kbe ? userData.kbe : "-"}
                </p>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] gap-[30px] max-w-[1200px]">
            <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
              <img src="assets/docs.png" className="w-[20px]" />
              <div className="uppercase">
                {t("profile_card.documents_title")}
              </div>
            </div>
            <div className="flex flex-wrap items-center w-full gap-x-[30px]">
              {userFilesData?.length > 0 ? (
                userFilesData.map((doc, index) => (
                  <div className=" flex justify-between w-[400px] bg-white shadow-md rounded-2xl p-[2%]">
                    <div>
                      {index === 0
                        ? "Заявление о присоединении"
                        : "Договор доступа к услугам"}
                    </div>
                    <button
                      className="cursor-pointer min-w-6"
                      onClick={() => {
                        handleDownloadClick(doc,index);
                      }}
                    >
                      <img src="/icons/download.svg" alt="Download" />
                    </button>
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center w-full h-full text-base text-gray-600 font-semibold">
                  {t("profile_card.no_documents")}!
                </div>
              )}
            </div>
          </div>
          <div className="pb-[2px]"></div>
        </div>
      )}
      {/* {(formik) => (

<div className="flex  flex-col gap-[20px] max-w-[1050px] w-full">
<h1 className="text-[25px] font-bold">Личный кабинет пользователя</h1>
<div className="flex flex-row gap-[50px]">
<div className="bg-white flex-1 px-5 pb-5 pt-[16px] h-[600px] rounded-xl shadow-md flex flex-col max-w-[500px] w-full gap-[20px] ">
<div className="flex flex-col items-center gap-[20px]">
<img src="/assets/profile.png" className="max-w-[300px] w-full"></img>
<h1 className="text-[20px]">Контактные данные руководителя</h1>
</div>
<div className="flex gap-[20px] justify-between">
<p className={`${style.custom_underline} text-[17px]`}>ФИО Руководителяs</p>
<h1 className="text-[17px] font-bold">Базарбаев Арман Арманович</h1>
</div>
<div className="flex gap-[20px] justify-between">
<p className={`${style.custom_underline} text-[17px] `}>ИИН Руководителя</p>
<h1 className="text-[17px] font-bold">680530302340</h1>
</div>
<div className="flex gap-[20px] justify-between">
<p className={`${style.custom_underline} text-[17px] `}>Телефон </p>
<h1 className="text-[17px] font-bold">+7 (123) 456-78-90</h1>
</div>
<div className="flex gap-[20px] justify-between">
<p className={`${style.custom_underline} text-[17px] `}>E-mail</p>
<h1 className="text-[17px] font-bold">ivanov.ivan@gmail.com</h1>
</div>
</div>
<div className="flex-1 bg-white px-5 pb-5 pt-[16px] h-[600px] rounded-xl shadow-md flex flex-col max-w-[500px] w-full gap-[10px]">
<h1 className="text-[21px]">Данные организации</h1>
<div className="flex flex-col gap-[10px]">
<div className="flex flex-col  gap-[2px]">
<p className="font-light text-[15px]">Наименование организации</p>
<h2 className="font-bold text-[15px]">200540056823</h2>
</div>
<div className="flex flex-col  gap-[2px]">
<p className="font-light text-[15px]">ИИН/БИН Организации</p>
<h2 className="font-bold text-[15px]">200540056823</h2>
</div>
<div className="flex flex-col  gap-[2px]">
<p className="font-light text-[15px]">Адрес Организации</p>
<h2 className="font-bold text-[15px]">г.Астана, Есильский р-н,пр.Туран 46/2 офис-305</h2>
</div>
</div>
<div className="flex flex-col gap-[10px] mt-[8px]">
<div className="flex mt-[10px] justify-between">
  <p className="text-[16px] font-bold">Расчётный счёт</p>
  <input
        type="text"
        name="checkingAccount"
        value={formData.checkingAccount}
        onChange={handleChange}
        className="rounded-[5px] max-w-[50%] w-full"
        placeholder="Расчётный счёт"
      />
</div>
<div className="flex mt-[10px] justify-between">
        <p className="font-bold text-[16px]"> БИК</p>
        <select
          name="bic"
          onChange={handleChange}
          className="rounded-[5px] max-w-[50%] w-full"
        >
          {formData.bic.map(option => (
            <option key={option.value} value={option.value}>{option.text}</option>
          ))}
        </select>
      </div>
<div className="flex mt-[10px] justify-between">
<p className="font-bold text-[16px]">E-mail</p>
<input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className="rounded-[5px] max-w-[50%] w-full"
        placeholder="E-mail"
      />
</div>
<div className="flex mt-[10px] justify-between">
<p className="font-bold text-[16px]">Веб-сайт организации</p>
<input
        type="text"
        name="website"
        value={formData.website}
        onChange={handleChange}
        className="rounded-[5px] max-w-[50%] w-full"
        placeholder="Веб-сайт организации"
      />
</div>
<div className="flex mt-[10px] justify-between">
<p className="font-bold text-[16px]">Телефон</p>
<input
        type="tel"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        className="rounded-[5px] max-w-[50%] w-full"
        placeholder="Телефон"
      />
</div>
<div className="flex mt-[10px] justify-between">
        <p className="font-bold text-[16px]">Роль</p>
        <select
          name="role"
          onChange={handleChange}
          className="rounded-[5px] max-w-[50%] w-full"
        >
          {formData.role.map(option => (
            <option key={option.value} value={option.value}>{option.text}</option>
          ))}
        </select>
      </div>
</div>
</div>

</div>
<div className=" bg-white px-5 pb-5 pt-[16px] h-[100px]  rounded-xl shadow-md flex flex-col w-full gap-[10px]">
<h1>Документы</h1>

</div>
</div>
      )} */}
    </Formik>
  );
}

export default ProfileCard;
