import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../../../common/libs/normalizedLocale";
import style from "./index.module.css"; // Импорт модуля стилей

const LandingHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  const mapLocaleToPath = (locale: string) => {
    return locale === "kz" ? "kk" : locale || "en"; // Упрощение кода
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setMenuOpen(false);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className={style.headerMain}>
      <nav className={style.headerNav}>
        <a href={`/`}>
          <img
            src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
            alt="Logo"
            className={style.etrackLogo}
            width={182}
            height={26}
          />
        </a>
      </nav>
      <div className={style.headerSetting}>
        {["kz", "ru"].map((lang) => (
          <a
            key={lang}
            className={locale === `/${lang}` ? style.languageButtonActive : style.languageButton}
            href={`/${lang}`}
          >
            {lang === "kz" ? "Қаз" : lang === "ru" ? "Рус" : "Eng"}
          </a>
        ))}
      </div>
    </header>
  );
};

export default LandingHeader;
