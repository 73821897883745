import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import style from './index.module.css';

interface QrScannerComponentProps {
  onClose: () => void; // Функция для закрытия сканера
  onScanResult: (result: string) => void; // Функция для обработки результата сканирования
}

const QrScannerComponent: React.FC<QrScannerComponentProps> = ({ onClose, onScanResult }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [scanResult, setScanResult] = useState<string | null>(null);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    codeReader.decodeFromVideoDevice(null, videoRef.current!, (result, err) => {
      if (result) {
        setScanResult(result.getText());
        onScanResult(result.getText()); // Передаем результат сканирования в родительский компонент
      }

      if (err && !(err instanceof NotFoundException)) {
        console.error(err);
      }
    });

    return () => {
      codeReader.reset(); // Сбрасываем обработчик при размонтировании компонента
    };
  }, [onScanResult]);

  return (
    <div className={style.fullscreenContainer}>
      <video ref={videoRef} className={style.video} />
      <button className={style.closeButton} onClick={onClose}>
        Закрыть
      </button>
    </div>
  );
};

export default QrScannerComponent;
