import { t } from "i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import MockSkeletonProducts from "../ProductsCatalog/utils/MockSkeletonProducts";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PaginationState } from "@tanstack/react-table";
import { useAppSelector } from "../../shared/hook";
import Pagination from "../../shared/components/Table/Pagination";
import MockSkeleton from "../OrderList/utils/MockSkeleton";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { downloadCheque, getChequeByCode } from "../../common/api/nktApi";
import { useTranslation } from "react-i18next";

function NewCheque() {
  const [errorModal, setErrorModal] = useState(false);
  const [initialValues,setInitialValues] = useState(
    {
      fiscalDocumentNumber: 0,
      information: {
        id: 0,
        dateTime: "2024-08-28T06:39:57.348Z",
        dateTimeReceipt: "2024-08-28T06:39:57.348Z",
        userInn: "string",
        fiscalDocumentNumber: 0,
        kktRegId: "string",
        products: [
          {
            ntin: "string",
            name: "string",
            price: 0,
            quantity: 0,
            sum: 0
          }
        ]
      },
      codes: [
        {
          inflictedAt: "2024-08-28T06:39:57.348Z",
          status: "ISSUED",
          code: "string",
          productTypeCode: "string",
          name: "string",
          producer: "string",
          owner: "string"
        }
      ]
    }
  )
  const { t, i18n } = useTranslation();
  const { chequeId } = useParams();
  const [page, setPage] = useState(1)
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  interface StatusType {
    type: string;
    label: string;
  }

  useEffect(() => {
    console.log(chequeId,'123123');
    onLoad();
  }, [pagination, location,]);

  async function onLoad() {
    if (!chequeId) return;
    try {
      const orderResponse = await getChequeByCode(chequeId);
      console.log('123412',orderResponse, 'orderResponse');

      setInitialValues(orderResponse);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    }
  }
  
  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };
  const statusTypes: { [key: string]: StatusType } = {
    DRAFT: { type: "test", label: t("orderList2.draft") },
    ERROR: { type: "error", label: t("orderList2.error") },
    IN_PROCESSING: { type: "test", label: t("orderList2.in_processing") },
    READY_TO_RECEIVE: {
      type: "active",
      label: t("orderList2.ready_to_receive"),
    },
    PROCESSED_NO_FUNDS: {
      type: "error",
      label: t("orderList2.processed_no_funds"),
    },
    PROCESSED_PAID: { type: "active", label: t("orderList2.processed_paid") },
    UNSAVED: { type: "", label: t("orderList2.unsaved") },
    WITHDRAWN_FROM_CIRCULATION: { type: "test", label: t("orderList2.unsaved") },
    PRE_SALE: { type: "test", label: t("NewCheque.PRE_SALE") }
  };

  function getMarkStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "ISSUED":
        return {
          type: "ISSUED",
          label: t("documents_file_upload.code_status_issued"),
        };
      case "INFLICTED":
        return {
          type: "INFLICTED",
          label: t("documents_file_upload.code_status_inflicted"),
        };
      case "IN_CIRCULATION":
        return {
          type: "IN_CIRCULATION",
          label: t("documents_file_upload.code_status_in_circulation"),
        };
      case "WITHDRAWN_FROM_CIRCULATION":
        return {
          type: "WITHDRAWN_FROM_CIRCULATION",
          label: t("documents_file_upload.code_status_withdrawn"),
        };
      default:
        return { type: "", label: "-" };
    }
  }
  const handleDownloadClick = async (ChequeId: any) => {
    // try {
    //   setIsLoading(true);
    //   const fileBlob = await downloadCheque(ChequeId);
    //   const downloadUrl = window.URL.createObjectURL(fileBlob);
    //   const link = document.createElement("a");
    //   link.href = downloadUrl;
    //   link.setAttribute("download", `Чек №${fileBlob.fiscalDocumentNumber}.txt`);
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   window.URL.revokeObjectURL(downloadUrl); 
    // } catch (error) {
    //   console.error("Ошибка при скачивании файла:", error);
    // } finally {
    //   setIsLoading(false);
    // }
    try {
      setIsLoading(true);
      
      // Загружаем Blob-данные
      const fileBlob = await downloadCheque(ChequeId);
      
      // Читаем содержимое Blob как текст
      const textData = await fileBlob.text();
      
      // Парсим текст как JSON
      const jsonData = JSON.parse(textData);
    
      // Получаем fiscalDocumentNumber из JSON
      const fiscalDocumentNumber = jsonData.fiscalDocumentNumber;
    
      // Создаем новый Blob для скачивания файла
      const downloadBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
    
      // Создаем URL для Blob
      const downloadUrl = window.URL.createObjectURL(downloadBlob);
      const link = document.createElement("a");
    
      // Используем fiscalDocumentNumber для названия файла
      link.href = downloadUrl;
      link.setAttribute("download", `Чек №${fiscalDocumentNumber}.txt`);
    
      // Добавляем ссылку в DOM и инициируем скачивание
      document.body.appendChild(link);
      link.click();
    
      // Удаляем ссылку после скачивания
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
    
  };

  return (
    < >
      <div className="overflow-y-auto  w-full h-auto ">
        <div className="flex flex-row justify-between  w-full mt-[30px] max-h-[40px] items-center">
          <span className="font-medium text-[18px] text-nowrap">
          {t('NewCheque.chequeKkm')}: {initialValues.fiscalDocumentNumber || '-'}
          </span>
          <div className="flex flex-row gap-[20px] text-wrap">
            <button
              className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded  "
              type="button"
              onClick={() => navigate(`/chequesList`)}
            >
              {t("mainButtons.close")}
            </button>
            <button
              className="buttonEtrackc bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={()=>handleDownloadClick(chequeId)}
            >

              {t("mainButtons.download")}
            </button>
          </div>
        </div>

        <div className="flex flex-row gap-[30px] ">
          <span
            className={`cursor-pointer ${page == 1 ? 'border-b-[3px] text-green' : ''}`}
            onClick={() => setPage(1)}
          >{t('NewCheque.commonInfo')}</span>
          <span className={`cursor-pointer ${page == 2 ? 'border-b-[3px] text-green' : ''}`}
            onClick={() => setPage(2)}>{t('NewCheque.codes')}</span>
        </div>
        {page == 1 ?
          (<div className="flex flex-row gap-[20px] w-full mt-[30px] max-h-[60%] text-wrap">
            <div className=" max-w-[60%] w-full max-h-[280px]  flex flex-col overflow-visible bg-white pt-[10px] pl-[10px] relative rounded-xl shadow-md  gap-[15px]">
              <span className="font-medium text-[20px]">
                {t('NewCheque.chequeKkm')}: {initialValues.fiscalDocumentNumber || '-'}
              </span>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col w-[50%]">
                  <label className="font-light text-gray-600">{t('NewCheque.dateTime')}</label>
                  <span>
                    {/* {format(initialValues?.information.dateTime, " dd.MM.yyyy, HH:mm") || "-"} */}
                    {initialValues?.information.dateTime}
                    </span>
                </div>
                <div className="flex flex-col w-[50%]">
                  <label className="font-light text-gray-600">{t('NewCheque.dateTimeReceipt')}</label>
                  <span>
                    {/* {format(initialValues?.information.dateTimeReceipt, " dd.MM.yyyy, HH:mm") || "-"} */}
                    {initialValues?.information.dateTimeReceipt}
                    </span>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col w-[50%]">
                <label className="font-light text-gray-600">{t('NewCheque.userInn')}</label>
                <span>{initialValues.information.userInn || '-'}</span>
              </div>
              <div className="flex flex-col w-[50%]">
              <label className="font-light text-gray-600">{t('NewCheque.kktNumber')}</label>
              <span>{initialValues.information.kktRegId || '-'}</span>
              </div>
              </div>
              <div className="flex">
<div className="flex flex-col w-[50%]">
                <label className="font-light text-gray-600">{t('NewCheque.shift')}</label>
                {/* <span>{initialValues.information. || '-'}</span> */}1
              </div>

              </div>

            </div>

            <div className="overflow-y-auto max-w-[40%] w-full max-h-[80%] flex flex-col  overflow-visible bg-white pt-[10px] px-[10px]  relative rounded-xl shadow-md gap-[20px]">
              <span className="flex justify-center text-[20px] font-medium">
              {t('NewCheque.product')}
              </span>
              {initialValues?.information?.products?.map((product, index) => (
                <div key={index}>
                  <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-col w-full ">
                      <label className="font-light text-gray-600">{t('NewCheque.productTypeCode')}</label>
                      <span>{product.ntin || '-'}</span>
                    </div>
                    <div className="flex flex-col w-full">
                      <label className="font-light text-gray-600">{t('NewCheque.productName')}</label>
                      <span>{product.name || '-'}</span>
                    </div>
                    <div className="flex flex-col w-full">
                      <label className="font-light text-gray-600">{t('NewCheque.ntin')}</label>
                      <span>{product.ntin || '-'}</span>
                    </div>
                    <div className="flex flex-row gap-[10px] border-b-[3px] pb-[10px]">
                      <div className="flex flex-col w-full max-w-[calc(33%-10px)]">
                        <label className="font-light text-gray-600 w-full">{t('NewCheque.price')}</label>
                        <span>{product.price || '-'}</span>
                      </div>
                      <div className="flex flex-col w-full max-w-[calc(33%-10px)]">
                        <label className="font-light text-gray-600">{t('NewCheque.quantity')}</label>
                        <span>{product.quantity || '-'}</span>
                      </div>
                      <div className="flex flex-col w-full max-w-[calc(33%-10px)]">
                        <label className="font-light text-gray-600">{t('NewCheque.sum')}</label>
                        <span>{product.sum || '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>) : (
            <div className="flex flex-col w-full mt-[10px]">
              <div className="w-full">
                <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
                  <table className="max-w-full w-full leading-normal">
                    <thead className="border-b-2 border-gray-300">
                      <tr>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          №{t("NewCheque.number")}
                        </th>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          {t("NewCheque.dateApplied")}
                        </th>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                          scope="col"
                        >
                          {t("NewCheque.codeStatus")}
                        </th>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                          scope="col"
                        >
                          {t("NewCheque.codeStatus")}
                        </th>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          {t("NewCheque.productTypeCode")}
                        </th>
                        <th
                          className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                          scope="col"
                        >
                          {t("NewCheque.productName")}
                        </th>
                        <th
                          className="px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200  text-nowrap"
                          scope="col"
                        >
                          {t("NewCheque.packageType")}
                        </th>
                        <th
                          className="px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200  text-nowrap"
                          scope="col"
                        >
                          {t("NewCheque.producer")}
                        </th>
                        <th
                          className="px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200  text-nowrap"
                          scope="col"
                        >
                          {t("NewCheque.currentOwner")}
                        </th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                        <tbody className="font-normal text-center">
                          {new Array(10).fill(1).map((_, index) => (
                            <MockSkeleton index={index} />
                          ))}
                        </tbody>
                      </SkeletonTheme>
                    ) : (!initialValues?.codes || initialValues?.codes.length === 0) ? (
                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center py-5">
                            <div>
                              <EmptyListPlaceholderContent
                                title={"Коды отсутствуют"}
                              // description={t("orderList.noOrdersDescription")}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody className="font-normal text-center">
                        {initialValues?.codes?.map((value: any, index: any) => (
                          <tr
                            key={`tr-${index}-${value.id}`}
                          >
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                              {calculateItemNumber(index) || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 cursor-pointer "
                            >
                              {value?.inflictedAt}
                              {/* {format(value.inflictedAt, " dd.MM.yyyy, HH:mm") || "-"} */}
                            </td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                            <StatusBadge
                                  type={statusTypes[value.status]?.type || "unsaved"}
                                  fontSize="15px"
                                  circleSize="8px"
                                >
                                  {getMarkStatusType(value?.status)?.label ||
                                    t("documents_upload.unknownStatus")}
                                </StatusBadge>
                            </td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center w-[225px]">
    {value?.code?.length > 21 ? `${value.code.substring(0, 21)}` : value?.code || "-"}
</td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                            {value?.productTypeCode || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                              {value?.name || "-"}
                            </td>
                            <td
                              className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center max-w-[140px] text-nowrap"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                value?.note || t("documentLabels_3.no_note")
                              }
                            >
                              {value?.marksAmount || "-"}
                              <Tooltip id="my-tooltip" place="top" delayHide={1500} />
                            </td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {value.producer}

                            </td>
                            <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {value.owner}

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
                <Pagination
                  totalItems={tableData?.totalItems || 0}
                  totalPages={tableData?.totalPages || 0}
                  pagination={pagination}
                  setPagination={setPagination}
                />
              </div>

            </div>

          )
        }

      </div>

    </>
  );
}


export default NewCheque;
