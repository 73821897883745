import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./index.module.css";
import QrScannerComponent from "../Qr/QrScannerComponent";
import ModalComponent from "../ModalComponent/ModalWindow";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const LandingBody = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const [isScannerOpen, setIsScannerOpen] = useState(false); // Состояние для управления видимостью сканера
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для управления видимостью модалки
  const [scanResult, setScanResult] = useState<string | null>(null); // Состояние для хранения результата сканирования
  const handleVerify = async () => {
    if (!executeRecaptcha) {
      console.log("reCAPTCHA не загружена");
      return;
    }
    const token = await executeRecaptcha("action_name");
    console.log("reCAPTCHA token:", token);
  };
  const handleScanButtonClick = () => {
    setIsScannerOpen(true); // Открываем сканер при нажатии на кнопку
  };

  const handleCloseScanner = () => {
    setIsScannerOpen(false); // Закрываем сканер
  };

  const handleScanResult = (result: string) => {
    setScanResult(result); // Устанавливаем результат сканирования
    setIsScannerOpen(false); // Закрываем сканер
    setIsModalOpen(true); // Открываем модалку
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Закрываем модалку
  };
  const body = () => {
    return (
      <>

        <div className="w-full flex flex-col bg-white p-[30px] px-[30px] gap-[10px] border-b border-gray-200 ">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
          <img src="/assets/docs.png" className="w-[20px]" />
            <div className="uppercase">{t("productCard.title")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.fullName")}
              <p className="font-light">
              Браслет с шармами в виде звёзд, серебряный
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.publicationDateTime")}
            <p className="font-light">
            13.09.2024 11:41:44
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.shortName")}
            <p className="font-light">
            Браслет с шармами
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.creatorIIN")}
            <p className="font-light">
            123456789021
              </p>
            </div>
            
          </div>
        </div>
        
        <div className="w-full flex flex-col bg-white  p-[30px] gap-[30px] max-w-[1200px] border-b border-gray-200">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px] ">
          <img src={"/assets/docs.png"} className="w-[20px]" alt="icon" />
            <div className="uppercase">{t("productCard.productDescription")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.productType")}
              <p className="text-gray-600 font-light">
              Браслет с шармами в виде звёзд, серебряный: 00200000100100
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.ntin")}
            <p className="text-gray-600 font-light">
            00200000047498
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.tnvedCode")}
              <p className="text-gray-600 font-light">
              7113110000
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.tnvedDescription")}
              <p className="font-light">
              Ювелирные изделия и их части из серебра, имеющего или не имеющего гальваническое покрытие, плакированного или неплакированного другими драгоценными металлами
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.kpvedCode")}
            <p className="text-gray-600 font-light">
            32.12.13
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.kpvedDescription")}
            <p className="text-gray-600 font-light">
            Изделия ювелирные из металлов благородных и их части
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.countryOfOrigin")}
              <p className="font-light">
              AD
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.manufacturerNameRU")}
              <p className="font-light">
              Пандора лимитедПандора лимитед
              </p>
            </div>
          </div>
        </div>
  
        <div className="w-full flex flex-col bg-white  p-[30px] gap-[30px] max-w-[1200px]  border-b border-gray-200">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
            <img src="assets/docs.png" className="w-[20px]" />
            <div className="uppercase">{t("profile_card.participation_in_the_system")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
              <div className={style.tooltipWrapper}>
              {t("productCard.mainMaterial")}
                {/* <FaInfoCircle className={style.errorIcon} />
                <div className={style.tooltipText}>
                  {t('profile_card.registration_date_tooltip')}
                </div> */}
              </div>
              <p className="text-gray-600 font-light">
              Серебро
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.sampleCompliance")}
              <p className="font-light">
              925
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.coatingRU")}
              <p className="font-light">
              без покрытия
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.article")}
              <p className="font-light">
              598498C01-18
              </p>
            </div>
             <div className="text-gray-600 w-[300px] font-semibold">
             {t("productCard.trademark")}
              <p className="font-light">
              Pandora
              </p>
            </div>
            
          </div>
        </div>
  
        <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px]">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
          <img src="/assets/docs.png" className="w-[20px]" />
            <div className="uppercase">{t("productCard.inserts")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.insertMaterial")}
              <p className="text-gray-600 font-light">
              Бриллиант
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.insertCount")}
              <p className="text-gray-600 font-light">
                3
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.weight")}
              <p className="text-gray-600 font-light">
                15
              </p>
            </div>
           
          </div>
        </div>
        <div className="flex justify-end">
        <ModalButton
                type="button"
                onClick={()=>setIsModalOpen(false)}
                color={'green'}
              >
                {t("mainButtons.close")}
              </ModalButton>
        <div className="pb-[2px]"></div>
      </div>
     
      </>
    );
  };
  

  return (
    <div className={style.container}>
      <div className={style.middleContainer}>
        <button className={style.middleButton} onClick={handleScanButtonClick}>
          <img
            src={"/icons/datamatrix.svg"}
            alt="E-Track Logo"
            className={`h-6 pl-2 origin-left font-medium text-xl duration-200 brightness-0 invert`}
          />
          {t("Сканировать марку QR")}
        </button>
        <button onClick={handleVerify}>Проверить</button>

      </div>
      
      {/* Отображаем компонент сканера, если он открыт */}
      {isScannerOpen && (
        <div className={style.scannerOverlay}>
          <QrScannerComponent onClose={handleCloseScanner} onScanResult={handleScanResult} />
        </div>
      )}

      {/* Модалка для отображения результата сканирования */}
    <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} headerContent={t("Результат сканирования")} 
    content={body()}
    />
    </div>
  );
};

export default LandingBody;
