import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import Authorization from "../../pages/Authorization/Authorization";
import Registration from "../../pages/Authorization/Registration";
import SignIn from "../../pages/Authorization/SignIn";
import DocumentList from "../../pages/DocumentList/DocumentList";
import DocumentImportEAEU from "../../pages/Documents/DocumentImportEAEU/DocumentImportEAEU";
import DocumentImportOtherCountries from "../../pages/Documents/DocumentImportOtherCountries/DocumentImportOtherCountries";
import DocumentImportOtherCountriesOrders from "../../pages/Documents/DocumentImportOtherCountries/DocumentImportOtherCountriesOrders";
import DocumentInventoryResumption from "../../pages/Documents/DocumentInventoryResumption/DocumentInventoryResumption";
import DocumentInventoryResumptionOrders from "../../pages/Documents/DocumentInventoryResumption/DocumentInventoryResumptionOrders";
import DocumentInventoryWriteOff from "../../pages/Documents/DocumentInventoryWriteOff/DocumentInventoryWriteOff";
import DocumentInventoryWriteOffOrders from "../../pages/Documents/DocumentInventoryWriteOff/DocumentInventoryWriteOffOrders";
import DocumentTransferAcceptance from "../../pages/Documents/DocumentTransferAcceptance/DocumentTransferAcceptance";
import DocumentTransferAcceptanceOrders from "../../pages/Documents/DocumentTransferAcceptance/DocumentTransferAcceptanceOrders";
import MainScreen from "../../pages/MainScreen";
import OrderList from "../../pages/OrderList/OrderList";
import NewOrder from "../../pages/Orders/NewOrder";
import ProfileCard from "../../pages/ProfileCard/ProfileCard";
import LoggedInLayout from "../../shared/components/LoggedInLayout";
import { RedirectAuthorization } from "../../shared/hoc";
import DocumentImportEAEUOrders from "../../pages/Documents/DocumentImportEAEU/DocumentImportEAEUOrders";
import PageNotFound from "../../shared/Ui/PageNotFound/PageNotFound";
import PageErrorMessage from "../../shared/Ui/PageErrorMessage/PageErrorMessage";
import MyCards from "../../pages/ProductsCatalog/MyCards";
import AllCards from "../../pages/ProductsCatalog/AllCards";
import ProductPersonalization from "../../pages/Documents/DocumentPersalization/ProductPersonalization";
import ProductCard from "../../pages/ProductCard/ProductCard";
import ProductPersonalizationOrders from "../../pages/Documents/DocumentPersalization/ProductPersonalizationOrders";
import NewCheque from "../../pages/Cheques/NewCheque";
import ChequesList from "../../pages/Cheques/ChequesList";
import QrMarks from "../../pages/QrMarks";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';


export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/authorization" element={<Authorization />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/qr-marks" element={<QrMarks />} />
      


      <Route
        path="/"
        element={
          <RedirectAuthorization>
            <LoggedInLayout />
          </RedirectAuthorization>
        }
      >
        <Route index element={<MainScreen />} />
        <Route path="orders" element={<OrderList />} />
        <Route
          path="orders/new"
          element={
            <RedirectAuthorization>
              <NewOrder />
            </RedirectAuthorization>
          }
        />
        <Route path="orders/:orderId" element={<NewOrder />} />
        <Route path="main" element={<MainScreen />} />
        <Route path="profile" element={<ProfileCard />} />
        <Route path="documents/inbox" element={<DocumentList type="INBOX" />} />
        <Route
          path="documents/personalization"
          element={
            <RedirectAuthorization>
              <DocumentList type="PERSONALIZATION" />
            </RedirectAuthorization>
          }
        />
        <Route
          path="documents/sent"
          element={
            <RedirectAuthorization>
              <DocumentList type="SENT" />
            </RedirectAuthorization>
          }
        />
        <Route
          path="documents/drafts"
          element={<DocumentList type="DRAFT" />}
        />

        <Route
          path="documents/personalization/new"
          element={<ProductPersonalization />}
        />
        <Route
          path="documents/personalization/:ProductPersonalizationId"
          element={<ProductPersonalization />}
        />

        {/* Уведомление  из третьих стран start */}
        <Route
          path="documents/import-other-countries/new"
          element={<DocumentImportOtherCountries />}
        />
        <Route
          path="documents/import-other-countries/new/orders"
          element={<DocumentImportOtherCountriesOrders />}
        />
        <Route
          path="documents/import-other-countries/:importOtherCountriesDocumentId"
          element={<DocumentImportOtherCountries />}
        />
        <Route
          path="documents/import-other-countries/:importOtherCountriesDocumentId/orders"
          element={<DocumentImportOtherCountriesOrders />}
        />
        {/* Уведомление  из третьих стран end */}
        {/* Уведомление о повторном вводе в оборот start */}
        <Route
          path="documents/inventory-resumption/new"
          element={<DocumentInventoryResumption />}
        />
        <Route
          path="documents/inventory-resumption/:inventoryResumptionDocumentId"
          element={<DocumentInventoryResumption />}
        />
        <Route
          path="documents/inventory-resumption/new/orders"
          element={<DocumentInventoryResumptionOrders />}
        />
        <Route
          path="documents/inventory-resumption/:inventoryResumptionDocumentId/orders"
          element={<DocumentInventoryResumptionOrders />}
        />
        {/* Уведомление о повторном вводе в оборот end */}
        {/* Уведомление из ЕАЭС start */}
        <Route
          path="documents/import-eaeu/new"
          element={<DocumentImportEAEU />}
        />
        <Route
          path="documents/import-eaeu/:importEaeuDocumentId"
          element={<DocumentImportEAEU />}
        />
        <Route
          path="documents/import-eaeu/new/orders"
          element={<DocumentImportEAEUOrders />}
        />
        <Route
          path="documents/import-eaeu/:importEaeuDocumentId/orders"
          element={<DocumentImportEAEUOrders />}
        />
        {/* Уведомление из ЕАЭС end */}
        {/* Акт приема-передачи товаров start */}
        <Route
          path="documents/transfer-acceptance/new"
          element={<DocumentTransferAcceptance />}
        />
        <Route
          path="documents/transfer-acceptance/:transferAcceptanceDocumentId"
          element={<DocumentTransferAcceptance />}
        />
        <Route
          path="documents/transfer-acceptance/new/orders"
          element={<DocumentTransferAcceptanceOrders />}
        />
        <Route
          path="documents/transfer-acceptance/:transferAcceptanceDocumentId/orders"
          element={<DocumentTransferAcceptanceOrders />}
        />
        {/* Акт приема-передачи товаров start */}

        {/* Уведомление о выводе из оборота start */}
        <Route
          path="documents/inventory-write-off/new"
          element={<DocumentInventoryWriteOff />}
        />
        <Route
          path="documents/inventory-write-off/:writeOffDocumentId"
          element={<DocumentInventoryWriteOff />}
        />
        <Route
          path="documents/inventory-write-off/new/orders"
          element={<DocumentInventoryWriteOffOrders />}
        />
        <Route
          path="documents/inventory-write-off/:writeOffDocumentId/orders"
          element={<DocumentInventoryWriteOffOrders />}
        />
        <Route
          path="documents/personalization/:ProductPersonalizationId/:jewelryId"
          element={<ProductPersonalizationOrders />}
        />
        <Route
          path="products-catalog/my-cards"
          element={<MyCards />}
        />
        <Route
          path="/chequesList"
          element={<ChequesList />}
        />
        <Route
          path="/cheque/:chequeId"
          element={<NewCheque />}
        />
        <Route
          path="products-catalog/all-cards"
          element={<MyCards />}
        />
        <Route
          path="products-catalog/all-cards/:ntin"
          element={<AllCards />}
        />
        <Route
          path="/product-page/all-cards/:productTypeCode"
          element={<ProductCard />}
        />;
        <Route
          path="/product-page/my-cards/:productTypeCode"
          element={<ProductCard />}
        />;
        <Route
          path="*"
          element={<PageNotFound />}
        />
        <Route
          path="/error"
          element={<PageErrorMessage />}
        />
      </Route >
      <Route path="/error" element={<PageErrorMessage />} />

      <Route path="*" element={<PageNotFound />} />
    </>,
  ),
);
