import { t } from "i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import MockSkeletonProducts from "../ProductsCatalog/utils/MockSkeletonProducts";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginationState } from "@tanstack/react-table";
import { useAppSelector } from "../../shared/hook";
import Pagination from "../../shared/components/Table/Pagination";
import MockSkeleton from "../OrderList/utils/MockSkeleton";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { ChequesListDownload, getCheqeuesList, GetjewelryGroup, getNtinCode} from "../../common/api/nktApi";
import { GetReferenceService } from "../../common/api/ordersApi";
import { Field, Form, Formik } from "formik";
import style from "./ChequesList.module.css";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import classNames from "classnames";
import Calendar from "../../shared/Ui/Calendar/Calendar";
import { normalizedLocale } from "../../common/libs/normalizedLocale";
import { useTranslation } from "react-i18next";


function ChequesList() {
    const [errorModal, setErrorModal] = useState(false);
    const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
    const handleTooltipToggle = (id: number) => {
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
    };
    const { t, i18n } = useTranslation();
    const [ntinSelect,setNtinSelect] = useState([])
    const locale = normalizedLocale(i18n.language);
    const [releaseMethods, setReleaseMethods] = useState([]);
    const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [jewelryGroup,setJewelryGroup] = useState<any>([])
    const [tableData, setTableData] = useState<any>({
        values: [],
        totalItems: 0,
        totalPages: 0,
      });
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = useAppSelector((state) => state.userSettings.pageSize);
    type PaginationState = {
      pageIndex: number;
      pageSize: number;
    };
    
    const [pagination, setPagination] = useState<PaginationState>(() => {
      // Попробуйте получить состояние пагинации из localStorage
      const savedPagination = localStorage.getItem('paginationCheques');
      return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
    });
    useEffect(() => {
      // Сохраните состояние пагинации в localStorage при каждом изменении
      localStorage.setItem('paginationCheques', JSON.stringify(pagination));
    }, [pagination]);
      interface StatusType {
        type: string;
        label: string;
      }
      useEffect(() => {
        const clearSelected = (e: any) => {
          if (!e.target.classList.contains("react-tooltip")) {
            setIsFilterOpen(false);
          }
        };
        window.addEventListener("click", clearSelected);
    
        return () => window.removeEventListener("click", clearSelected);
      }, []);
      useEffect(() => {
        GetjewelryGroup()
        .then((response: any) => {
          const optionsOgd = response?.map((e: any) => ({
            value: e.id,
            name:e.name,
            label: `${e.productTypeCode}`,
          }));
  
          setJewelryGroup(optionsOgd);
        })
        .catch((error) => {
          console.error("Error fetching bank catalog list:", error);
        });
      }, [pagination]);

      useEffect(() => {
        onLoad()
      }, [pagination]);

      const debounce = (func: Function, delay: number) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        return (...args: any) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
    
      // const debouncedSearch = useCallback(
      //   debounce(async (value: string) => {
      //     if (value?.length >= 3) {
      //       try {
      //         const result = await getNtinCode(value);
      //         const options = result.map((org: any) => ({
      //           value: org,
      //           label: org,
      //         }));
      //         console.log('setset');
      //         setNtinSelect(options);
      //       } catch (error) {
      //         console.error("Error in debounced search:", error);
      //       }
      //     } else if (value?.length >= 1) {
      //       setNtinSelect([]);
      //     }
      //   }, 300), 
      //   [],
      // );
      // const debouncedSearch = useCallback(
      //   debounce(async (productTypeCode: string) => {
      //     if (productTypeCode?.length >= 3) { // Проверка кода изделия
      //       try {
      //         const result = await getNtinCode(productTypeCode); // запрос с кодом изделия
      //         const options = result.map((org: any) => ({
      //           value: org.ntin,
      //           label: org.name,
      //         }));
      //         console.log('setset');
      //         setNtinSelect(options);
      //       } catch (error) {
      //         console.error("Error in debounced search:", error);
      //       }
      //     } else {
      //       setNtinSelect([]); // Если код изделия короткий, очистить опции
      //     }
      //   }, 300),
      //   []
      // );
      const debouncedSearch = useCallback(
        debounce(async (inputValue: string, productTypeCode: string) => {
          if (inputValue.length >= 3) {
            try {
              const result = await getNtinCode(inputValue,productTypeCode); // запрос с кодом изделия
              const options = result.map((org: any) => ({
                value: org.ntin,
                label: org.name,
              }));
              console.log('setset');
              setNtinSelect(options);
            } catch (error) {
              console.error("Error in debounced search:", error);
            }
          } else {
            setNtinSelect([]); // очистить опции, если длина строки меньше 3 символов
          }
        }, 300),
        []
      );
      

      async function onLoad() {
        setIsLoading(true);
        try {
          const response = await getCheqeuesList(
            pagination.pageIndex + 1,
            pagination.pageSize,
            initialValues.fiscalDocumentNumber,
            initialValues.kktRegId,
            initialValues.operationType,
            initialValues.status,
            initialValues.refJewelryGroupId,
            initialValues.ntin,
            startDate && format(startDate, "dd.MM.yyyy"),
        endDate
          ? format(endDate, "dd.MM.yyyy")
          : startDate
            ? format(startDate, "dd.MM.yyyy")
            : undefined,
          );
          setTableData({
            values: response?.checks,
            currentPage: response?.currentPage,
            itemsPerPage: response?.itemsPerPage,
            totalItems: response?.totalItems,
            totalPages: response?.totalPages,
          });
        } catch (error) {
          console.error("Error fetching user info:", error);
        } finally {
          setIsLoading(false);
        }
      }
    
      const toggleSort = () => {
        const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
        setSort(newSortDirection);
      };
    
    const calculateItemNumber = (index: any) => {
            return pagination.pageIndex * pagination.pageSize + index + 1;
        };
        const statusTypes: { [key: string]: StatusType } = {
          DRAFT: { type: "test", label: t("documentStatus.draft") },
          ERROR: { type: "error", label: t("documentStatus.error") },
          IN_PROCESSING: { type: "test", label: t("documentStatus.in_processing") },
          PROCESSED: { type: "active", label: t("documentStatus.processed") },
          IN_ACCEPTANCE: { type: "test", label: t("documentStatus.in_acceptance") },
          IN_CONFIRMATION: {
            type: "test",
            label: t("documentStatus.in_confirmation"),
          },
          WITHDRAWN: { type: "error", label: t("documentStatus.withdrawn") },
          REJECTED: { type: "error", label: t("documentStatus.rejected") },
          SIGNED_BY_RECIPIENT: {
            type: "active",
            label: t("documentStatus.signed_by_recipient"),
          },
          NOT_PROCESSED : { type: "error", label: t("documentStatus.not_processed") }
        };

    const renderActions = (
        value: any,
        setErrorModal: any,
        navigate: any,
        setAlertOpen?: any,
        setIsCheckOpen?: any,
      ) => {
        const actions = [];
        actions.push({
          title: t("orderList.show"),
          action: () => {
            if(value.status == 'PROCESSED'){
              navigate("/cheque/" + value?.id)
            }

        }});
        return actions;
      };
      const [initialValues, setInitialValues] = useState({
        fiscalDocumentNumber: "",
        kktRegId:'',
        operationType:null,
        status:null,
        refJewelryGroupId:'',
        ntin:null
      });
      //тут будут запросы для полей фильтров
      async function onSearchOpen() {
        releaseMethods?.length === 0 &&
          (await GetReferenceService("app/release-methods/list")
            .then((response: any) => {
              const options =
                response &&
                response?.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }));
              setReleaseMethods(options);
            })
            .catch((error: any) => {
              console.error("Error fetching bank catalog list:", error);
            }));
      }


      const renderFilterInputs = () => {
        return (
          <Formik
            initialValues={initialValues}
            onSubmit={() => {
              setPagination((prevValues: PaginationState) => {
                return { ...prevValues, pageIndex: 0 };
              });
              setIsFilterOpen(false);
            }}
            // enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {(formik) => (
              <Form className=" w-[300px] p-[10px] ">
                <div className="flex flex-row align-center justify-between  ">
                  <p className="my-3 text-lg font-semibold ">
                    Фильтр
                  </p>
                  <button
                    type="reset"
                    onClick={() => {
                      setIsFilterOpen(false);
                    }}
                  >
                    <img src="/icons/close.svg" />
                  </button>
                </div>
    
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      Номер чека ККМ
                    </label>
                  </div>
                  <Field
                    type="text"
                    name="fiscalDocumentNumber"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik?.values?.fiscalDocumentNumber}
                    onChange={(e: any) => {
                      formik.setFieldValue("fiscalDocumentNumber", e.target.value);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, fiscalDocumentNumber: e.target.value };
                      });
                    }}
                  />
                </div>
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    Рег. номер ККМ
                    </label>
                  </div>
                  <Field
                    type="text"
                    name="kktRegId"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik?.values?.kktRegId}
                    onChange={(e: any) => {
                      formik.setFieldValue("kktRegId", e.target.value);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, kktRegId: e.target.value };
                      });
                    }}
                  />
                </div>
    
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    Тип чека ККМ
                    </label>
                  </div>
                  <Field
                    value={formik?.values?.operationType || null}
                    component={SelectCustomFormik}
                    name="operationType"
                    placeholder={t("documentLabels.choose")}
                    isClearable={true}
                    size="dm"
                    isMulti={false}
                    options={[
                      { value: "1", label: "Чек продажи" },
                      { value: "2", label: "Чек возврата" },
                  ]}
                    onChange={(options: any) => {
                      formik.setFieldValue("operationType", options);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, operationType: options };
                      });
                    }}
                  />
                </div>
    
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    Статус обработки чека ККМ
                    </label>
                  </div>
                  <Field
                    value={formik?.values?.status || null}
                    component={SelectCustomFormik}
                    name="status"
                    placeholder={t("documentLabels.choose")}
                    isClearable={true}
                    size="dm"
                    options={[
                      { value: "NOT_PROCESSED", label: "Не обработан" },
                      { value: "PROCESSED", label: "Обработан" },
                  ]}
                    onChange={(options: any) => {
                      formik.setFieldValue("status", options);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, status: options };
                      });
                    }}
                  />
                </div>
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    Код типа изделия
                    </label>
                  </div>
                  {/* <Field
                    isMulti={true}
                    value={formik?.values?.refJewelryGroupId || null}
                    component={SelectCustomFormik}
                    name="refJewelryGroupId"
                    placeholder={t("documentLabels.choose")}
                    isClearable={true}
                    size="dm"
                    options={jewelryGroup}
                    onChange={(options: any) => {
                      formik.setFieldValue("refJewelryGroupId", options);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, refJewelryGroupId: options };
                      });
                    }}
                  /> */}
                  <Field
  isMulti={true}
  value={formik?.values?.refJewelryGroupId || null}
  component={SelectCustomFormik}
  name="refJewelryGroupId"
  placeholder={t("documentLabels.choose")}
  isClearable={true}
  size="dm"
  options={jewelryGroup}
  onChange={(selectedOption: any) => {
    setInitialValues({
      ...initialValues,
      ntin:null
    });
    formik.setFieldValue("ntin", null);
    formik.setFieldValue("refJewelryGroupId", selectedOption);
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      refJewelryGroupId: selectedOption,
    }));
    console.log('selectedOption',jewelryGroup[selectedOption-1]?.label);
    
    // console.log(111,jewelryGroup[selectedOption-1].name);
    // debouncedSearch(jewelryGroup[selectedOption-1].name)

    // if (jewelryGroup[selectedOption-1]?.name) {
    //   debouncedSearch(jewelryGroup[selectedOption-1]?.name);
    // }
  }}
/>
                </div>
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                    Наименование изделия
                    </label>
                  </div>
                  {/* <Field
                    value={formik?.values?.ntin || null}
                    component={SelectCustomFormik}
                    loadingMessage={false}
                    name="ntin"
                    placeholder={t("documentLabels.choose")}
                    isClearable={true}
                    size="dm"
                    options={ntinSelect}
                    onInputChange={debouncedSearch}
                    isDisabled={formik?.values?.refJewelryGroupId?.length > 1}
                    onChange={(options: any) => {
                      formik.setFieldValue("ntin", options);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, ntin: options };
                      });
                    }}
                  /> */}
                  <Field
  value={formik?.values?.ntin || null}
  component={SelectCustomFormik}
  name="ntin"
  placeholder={t("documentLabels.choose")}
  isClearable={true}
  size="dm"
  options={
    ntinSelect
  } // опции, загруженные по запросу
  onInputChange={(inputValue: string) => {
    debouncedSearch(inputValue, jewelryGroup[Number(formik.values.refJewelryGroupId[0]) - 1]?.label);
  }}
  isDisabled={formik?.values?.refJewelryGroupId?.length > 1}
  onChange={(selectedOption: any) => {
console.log('formik',formik.values.refJewelryGroupId);

    
    formik.setFieldValue("ntin", selectedOption);
    setInitialValues((prevValues: any) => ({
      ...prevValues,
      ntin: selectedOption,
    }));
  }}
/>
                </div>
                <div className="flex justify-center">
                <Calendar
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate} 
              setEndDate={setEndDate}
            />
                </div>
                <div className="flex flex-row gap-[10px]">
                  <button
                    type="submit"
                    className=" bg-green text-white font-semibold py-2 px-4 rounded"
                  >
                    {t("mainButtons3.apply")}
                  </button>
                  <button
                    type="reset"
                    className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                    onClick={() => {
                      setStartDate(undefined);
                      setEndDate(undefined);
                      setInitialValues({
                        fiscalDocumentNumber:'',
                        kktRegId:'',
                        operationType:null,
                        status:null,
                        refJewelryGroupId:'',
                        ntin:null
    
                      });

                      formik.resetForm({
                        values: {
                          fiscalDocumentNumber:'',
                          kktRegId:'',
                          operationType:null,
                          status:null,
                          refJewelryGroupId:'',
                          ntin:null
    
                        },
                      });
                      setPagination((prevValues: PaginationState) => {
                        return { ...prevValues, pageIndex: 0 };
                      });
                      // setIsFilterOpen(false);
                    }}
                  >
                    {t("mainButtons3.reset")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );
      };

  return (
   < >
   <div className="flex flex-col w-[100%]">
 
    <div className="flex flex-col w-full">
    <span className="my-3 text-xl font-semibold">
    {t('submenu.receipts')}
    </span>
       <div className="bg-white  my-5 p-5   relative rounded-xl shadow-md flex flex-row align-center justify-end">
       <div className="flex flex-row gap-[20px]">
       <button
  type="button"
  onClick={async (e) => {
    e.preventDefault();
    const now = new Date();

// Форматируем дату и время в желаемый формат
const formattedDate = now.toLocaleDateString('ru-RU');
const formattedTime = now.toLocaleTimeString('ru-RU').replace(/:/g, '-');
const fileName = `Список чеков (${formattedDate} ${formattedTime}).xlsx`;
    try {
      // Вызов вашей функции для получения данных
      const response = await ChequesListDownload(
        initialValues.fiscalDocumentNumber,
        initialValues.kktRegId,
        initialValues.operationType,
        initialValues.status,
        initialValues.refJewelryGroupId,
        initialValues.ntin,
        startDate && format(startDate, "dd.MM.yyyy"),
        endDate
          ? format(endDate, "dd.MM.yyyy")
          : startDate
          ? format(startDate, "dd.MM.yyyy")
          : undefined,
      );

      // Проверка успешности ответа и типа данных
      if (response) {
        // Создание Blob объекта из бинарных данных
        const fileBlob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        // Создание ссылки для скачивания
        const downloadUrl = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Не удалось получить данные для скачивания.");
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    }
  }}
  className="bg-green text-white font-semibold py-2 px-4 rounded"
  data-tooltip-offset={4}
  data-tooltip-id="filter-type"
>
  {t("mainButtons.download")}
</button>

            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onSearchOpen();
                setIsFilterOpen(true);
              }}
              className=" bg-green text-white font-semibold py-2 px-4 rounded"
              data-tooltip-offset={4}
              data-tooltip-id="filter-type"
            >
              {t("mainButtons3.filter")}
            </button>
            <button
              // onClick={() => {
              //   setStartDate(undefined);
              //   setEndDate(undefined);
              //   setInitialValues({
              //     orderNumber: "",
              //     orderStatus: [],
              //     releaseMethod: null,
              //   });
              //   setPagination((prevValues: PaginationState) => {
              //     return { ...prevValues, pageIndex: 0 };
              //   });
              // }}
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
                setInitialValues({
                  fiscalDocumentNumber:'',
                  kktRegId:'',
                  operationType:null,
                  status:null,
                  refJewelryGroupId:'',
                  ntin:null
                })
                setPagination((prevValues: PaginationState) => {
                  return { ...prevValues, pageIndex: 0 };
                });
              }}
            >
              <img src="/icons/update.svg" />
            </button>
          </div>
       </div>
       <div className="w-full">
          {/* <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
            <table className="max-w-full w-full leading-normal">
              <thead className="border-b-2 border-gray-300"> */}
              <div className="rounded-lg bg-white overflow-auto">
  <table className="max-w-full w-full leading-normal">
    <thead className="border-b-2 border-gray-300">
                <tr>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    №{t("documents_orders.number")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t('ChequesList.fiscalDocumentNumber')}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                    scope="col"
                  >
                    {t('ChequesList.kktRegId')}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                    scope="col"
                  >
                    {t('ChequesList.dateTime')}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t('ChequesList.operationType')}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t('ChequesList.status')}
                  </th>
                 
                </tr>
              </thead>
              {isLoading ? (
                <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                  <tbody className="font-normal text-center">
                    {new Array(10).fill(1).map((_, index) => (
                      <MockSkeleton index={index} />
                    ))}
                  </tbody>
                </SkeletonTheme>
              ) : tableData?.values?.length === 0 || !tableData?.values ? (
                <tbody>
                  <tr>
                    <td colSpan={8} className="text-center py-5">
                      <div>
                        <EmptyListPlaceholderContent
                          title={t("productPersonalization.check_empty")}
                          // description={t("orderList.noOrdersDescription")}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="font-normal text-center">
                  {tableData?.values?.map((value: any, index: any) => (
                    <tr 
                    key={`tr-${index}-${value.id}`}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() =>{
                      console.log('navigateTo',("/cheque/" + value?.id));
                      {value.status == 'PROCESSED' ?  navigate("/cheque/" + value?.id): {}}} }
                     
                  >
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {calculateItemNumber(index) || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 cursor-pointer "
                      >
                        {value?.fiscalDocumentNumber || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                      {value?.kktRegId || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center w-[225px]">
                      {/* {format(value?.dateTime, "dd.MM.yyyy") || '-'} */}
                      {value.dateTime || ''}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">

                      {value?.operationType == '1' ? "Чек продажи" : 'Чек возврата'}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                      <div className="flex justify-center">
                          <StatusBadge
                            type={statusTypes[value.status]?.type || "unsaved"}
                            fontSize="15px"
                            circleSize="8px"
                          >
                            {statusTypes[value.status]?.label ||
                              t("documents_upload.unknownStatus")}
                          </StatusBadge>
                        </div>

                      </td>
                    {value.status == 'PROCESSED' ?(
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                       onClick={(e) => e.stopPropagation()} 
                      >
                          {/* <ActionDropwdownCell
                          key={`cell-${index}-${value.id}-acitons`}
                          id={value.id}
                          actions={[
                            {
                              title: t("orderList.show"),
                              action: () => {
                                navigate("/cheque/" + value?.id);
                              },
                            },
                          
                          ]}
                        /> */}
                     
<ActionDropwdownCell
          key={index}
          id={value.id}
          actions={renderActions(value,setErrorModal,navigate)}
          activeTooltipId={activeTooltipId}
          onTooltipToggle={handleTooltipToggle}
        />
                      </td>
                    ): (<td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                      onClick={(e) => e.stopPropagation()} 
                     >
                      </td>)}
                      
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <Pagination
            totalItems={tableData?.totalItems || 0}
            totalPages={tableData?.totalPages || 0}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
        <div className="z-[1000] relative	">
        <Tooltip
          isOpen={isFilterOpen}
          id="filter-type"
          className={classNames(style.wrapperTooltipFilter, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => setIsFilterOpen(true)}
          afterHide={() => setIsFilterOpen(false)}
        >
          <div onClick={(e) => e.stopPropagation()}>{renderFilterInputs()}</div>
        </Tooltip>
      </div>

    </div>

</div>

   </>
  );
}


export default ChequesList;
