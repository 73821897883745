import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import DocumentsService from "../../../../common/api/documentsApi";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import Calendar from "../../../../shared/Ui/Calendar/Calendar";
import CheckBox from "../../../../shared/Ui/CheckBox/CheckBox";
import { EmptyListPlaceholderContent } from "../../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../../../shared/components/Table/Pagination";
import { ITable } from "../../../../shared/components/Table/types";
import { useAppSelector } from "../../../../shared/hook";
import { generateAndDownloadExcel } from "../../../../shared/hooks/generateFiles";
import style from "./DocumentsSearch.module.css";
import { addMarksToProduct } from "../../../../common/api/nktApi";
import AlertBox from "../../../../shared/Ui/AlertBox/AlertBox";

export interface IDocumentsSearch {
  documentId: string | undefined;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleOnLoad: boolean;
  setToggleOnLoad: Dispatch<SetStateAction<boolean>>;
  personalization?: any;
}

interface StatusType {
  type: string;
  label: string;
}
 
export const DocumentsSearch: React.FC<IDocumentsSearch> = (props) => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState<ITable>();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const [selectedData, setSelectedData] = useState<number[]>([]);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [resultsSort, setResultsSort] = useState<"ASC" | "DESC">("DESC");
  const [resultsTableData, setResultsTableData] = useState<ITable>();
  const [resultsPagination, setResultsPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
  const [errorModal, setErrorModal] = useState<any>(null);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [initialValues, setInitialValues] = useState({
    productTypeCode: "",
    status: [],
  });
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);
  const [isFilterTooltipOpen, setIsFilterTooltipOpen] =useState<boolean>(false);

  useEffect(() => {
    console.log(2221111, props?.personalization?.productInfo?.productTypeCode);
  
    
    if (props?.personalization?.productInfo?.productTypeCode) {
      setInitialValues(prev => ({
        ...prev,
        productTypeCode: props?.personalization?.productInfo?.productTypeCode
      }));
    }
    
    if (props.isOpen) {
      onLoad()
    };
  }, [pagination, props.isOpen, sort]);

  const onModalOpen = () => props.setIsOpen(true);
  const onModalClose = () => {
    setSelectedData([])
    props.setIsOpen(false);
  };
  const [resultsModalOpen, setResultsModalOpen] = useState<boolean>(false);
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);

  const onResultsModalOpen = () => setResultsModalOpen(true);
  const onResultsModalClose = () => setResultsModalOpen(false);

  async function onLoad() {
    
    setIsTableDataLoading(true);
    props.documentId &&
      (await DocumentsService.searchMarksByDocument(
        props.documentId,
        pagination.pageIndex + 1,
        pagination.pageSize,
        sort,
        props?.personalization?.productInfo?.productTypeCode || initialValues?.productTypeCode || undefined,
        initialValues?.status || undefined,
        startDate && format(startDate, "dd.MM.yyyy"),
        endDate
          ? format(endDate, "dd.MM.yyyy")
          : startDate
            ? format(startDate, "dd.MM.yyyy")
            : undefined,
      )
        .then((response) => {
          setTableData({
            values: response?.marks,
            currentPage: response?.currentPage,
            itemsPerPage: response?.pageSize,
            totalItems: response?.totalItems,
            totalPages: response?.totalPages,
          });
        })
        .catch((error) => console.error("Error fetching user info:", error))
        .finally(() => {
          setIsTableDataLoading(false);
          if (resultsTableData?.values?.length === 0) {
            // onResultsModalClose();
          }
        }));
  }
  console.log(1111111, initialValues);
  

  async function onLoadResults() {
    onResultsModalOpen();
    setIsResultsLoading(true);

    if (props.documentId) {
      const request = props.personalization 
        ? addMarksToProduct(props.personalization.documentId, props.personalization.productId, selectedData, undefined)
        : DocumentsService.addMarksByCodes(
          props.documentId,
          true,
          true,
          selectedData,
          undefined,
        );
  
      await request
        .then((response) => {
          if (response?.problemMarks?.length === 0) {
            setIsResultsLoading(false);
            onResultsModalClose();
            setAlertOpen({
              status: `${selectedData.length - response.problemMarks.length > 0 ? 'SUCCESS':'error'}`,
              message: `Успешно добавлено марок: ${selectedData.length - response.problemMarks.length}`,
            });
          } else {
            setResultsTableData({
              values: response?.problemMarks,
              currentPage: 0,
              itemsPerPage: resultsPagination.pageSize,
              totalItems: response?.total,
              totalPages: 1,
            });
            setAlertOpen({
              status: `${selectedData.length - response.problemMarks.length > 0 ? 'SUCCESS':'error'}`,
              message: `Успешно добавлено марок: ${selectedData.length - response.problemMarks.length}`,
            });
          }
        })
        .catch((error) =>
          setErrorModal(
            `${t("documentLabels.error")}: ${error?.response?.data?.message}`,
          ),
        )
        .finally(() => {
          
          props.setToggleOnLoad(!props.toggleOnLoad);
          setIsResultsLoading(false);
          setSelectedData([]);
        })};
  }
console.log('alertss',alertOpen?.status);

  const renderFilterInputs = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={() => {
            setPagination((prevValues: PaginationState) => {
              return { ...prevValues, pageIndex: 0 };
            })
            
            setIsFilterTooltipOpen(false);
          }}
          // enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {(formik) => (
            <Form className="max-w-[1585px] w-full m-t-[20px]">
              <div className="flex flex-row align-center justify-between">
                <p className="my-3 text-lg font-semibold">
                  {t("documents_upload.filters")}
                </p>
                <button
                  type="reset"
                  onClick={() => {
                    setIsFilterTooltipOpen(false);
                  }}
                >
                  <img src="/icons/close.svg" />
                </button>
              </div>
              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>
                    {t("documents_upload.ntin")}
                  </label>
                </div>
                <Field
                  type="text"
                  name="productTypeCode"
                  placeholder={t("documentLabels.choose_option")}
                  className={style.placeholder}
                  disabled={props?.personalization?.productInfo?.productTypeCode}
                  value={formik?.values?.productTypeCode}
                  onChange={(e: any) => {
                    formik.setFieldValue("productTypeCode", e.target.value);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, productTypeCode: e.target.value };
                    });
                  }}
                />
              </div>
              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>{t("documents_file_upload.code_status")}</label>
                </div>
                <Field
                  value={formik?.values?.status || null}
                  component={SelectCustomFormik}
                  name="status"
                  placeholder={t("documentLabels.choose")}
                  size="dm"
                  isClearable={true}
                  isMulti={true}
                  options={props.personalization
                    ? [
                        {
                          value: "INFLICTED",
                          label: t("documents_file_upload.code_status_inflicted"),
                        },
                        {
                          value: "IN_CIRCULATION",
                          label: t("documents_file_upload.code_status_in_circulation"),
                        },
                      ]
                    : [
                        {
                          value: "ISSUED",
                          label: t("documents_file_upload.code_status_issued"),
                        },
                        {
                          value: "INFLICTED",
                          label: t("documents_file_upload.code_status_inflicted"),
                        },
                        {
                          value: "IN_CIRCULATION",
                          label: t("documents_file_upload.code_status_in_circulation"),
                        },
                        {
                          value: "WITHDRAWN_FROM_CIRCULATION",
                          label: t("documents_file_upload.code_status_withdrawn"),
                        },
                      ]}
                  onChange={(options: any) => {
                    formik.setFieldValue("status", options);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, status: options };
                    });
                  }}
                />
              </div>

              <Calendar
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />

              <div className="flex flex-row gap-[10px]">
                <button
                  type="button"
                  className=" bg-green text-white font-semibold py-2 px-4 rounded"
                  onClick={() => {
                    setPagination((prevValues: PaginationState) => {
                      return { ...prevValues, pageIndex: 0 };
                    })
                    setIsFilterTooltipOpen(false);
                    onLoad()
                  }}
                >
                  <div className="flex flex-row items-center justify-between gap-[4px]">
                    <p>{t("mainButtons3.apply")}</p>
                    {isTableDataLoading && (
                      <div className={`!w-6 !p-1 ${style.loader}`}></div>
                    )}
                  </div>
                </button>
                <button
                  type="reset"
                  className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setInitialValues({
                      productTypeCode: props?.personalization?.productInfo?.productTypeCode || "",
                      status: [],
                    });
                    formik.resetForm({
                      values: {
                        productTypeCode: props?.personalization?.productInfo?.productTypeCode || "",
                        status: [],
                      },
                    });
                    setPagination((prevValues: PaginationState) => {
                      return { ...prevValues, pageIndex: 0 };
                    });
                  }}
                >
                  <p>{t("mainButtons3.reset")}</p>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const toggleSort = () => {
    const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
    setSort(newSortDirection);
  };

  const selectAllCheckboxes = (e: any) => {
    const checkboxOptions = tableData?.values?.map((item) => item?.id) || [];
    if (e?.target?.checked === true) {
      for (let i = 0; i < checkboxOptions?.length; i++) {
        selectedData?.indexOf(checkboxOptions[i]) === -1 &&
          setSelectedData((oldValues) => [...oldValues, checkboxOptions[i]]);
      }
    } else {
      for (let i = 0; i < checkboxOptions?.length; i++) {
        setSelectedData((oldValues) =>
          oldValues.filter((item) => item !== checkboxOptions[i]),
        );
      }
    }
  };

  const isAllChecked = () => {
    const checkboxOptions = tableData?.values?.map((item) => item?.id) || [];
    const isAllChecked = checkboxOptions.every((item) =>
      selectedData.includes(item),
    );
    return isAllChecked;
  };

  const handleSelection = (e: any, id: number) => {
    if (selectedData?.includes(id)) {
      setSelectedData((oldValues) => {
        return oldValues.filter((item) => item !== id);
      });
    } else {
      setSelectedData((oldValues) => [...oldValues, id]);
    }
  };

  const renderTable = () => {
    return (
      <div>
        <div
          className="w-full"
          onClick={() => isFilterTooltipOpen && setIsFilterTooltipOpen(false)}
        >
          <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 align-center justify-between">
            <p>{t("documents_orders.add_codes")}</p>
            <div className="bg-white p-5 relative flex flex-row align-center justify-between">
              <div className="flex flex-row gap-[10px]">
                <button
                  type="button"
                  onClick={() => setIsFilterTooltipOpen(true)}
                  className=" bg-green text-white font-semibold py-2 px-4 rounded"
                  data-tooltip-offset={4}
                  data-tooltip-id="doc-search-filter-type"
                >
                  {t("mainButtons3.filter")}
                </button>
                <button
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setInitialValues({
                      productTypeCode: props?.personalization?.productInfo?.productTypeCode || "",
                      status: [],
                    });
                    setPagination((prevValues: PaginationState) => {
                      return { ...prevValues, pageIndex: 0 };
                    });
                  }}
                >
                  <img src="/icons/update.svg"></img>
                </button>
              </div>
            </div>
          </div>
          <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
            <table className="max-w-full w-full leading-normal">
              <thead className="border-b-2 border-gray-300">
                <tr>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 flex flex-col items-center justify-between"
                    scope="col"
                  >
                    {t("documents_upload.select")}
                    <CheckBox
                      id={"checkbox-all"}
                      onChange={(e: any) => selectAllCheckboxes(e)}
                      checked={isAllChecked()}
                    />
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    №{t("documents_upload.serialNumber")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_file_upload.code")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.ntin")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_upload.orderNumber")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("documents_orders.product_name")}
                  </th>
                </tr>
              </thead>
              {tableData?.values?.length === 0 || !tableData?.values ? (
                <tbody>
                  <tr>
                    <td colSpan={8} className="text-center py-5">
                      <div>
                        <EmptyListPlaceholderContent
                          title={t("documents_upload.noData")}
                          description={''}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="font-normal text-center">
                  {tableData?.values?.map((value, index) => (
                    <tr key={`tr-${index}-${value.id}`}>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        <CheckBox
                          className="table-checkbox-select"
                          id={`checkbox-${value.id}`}
                          onChange={(e: PointerEvent) =>
                            handleSelection(e, value?.id)
                          }
                          checked={selectedData?.includes(value?.id)}
                        />
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        {index + 1 || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        {value?.code || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        {value?.productTypeCode || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        {value?.orderNumber || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                        {value?.productName || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="mx-7 my-2 text-left text-gray-600 font-semibold">
            <span>
              {t("documents_upload.selectedCount", {
                selectedCount: selectedData?.length,
                totalItems: tableData?.totalItems || 0,
              })}
            </span>
          </div>
          <Pagination
            totalItems={tableData?.totalItems || 0}
            totalPages={tableData?.totalPages || 0}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
        <div className="z-[1000] relative">
          <Tooltip
            isOpen={isFilterTooltipOpen}
            id="doc-search-filter-type"
            className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
            openOnClick={true}
            clickable={true}
            noArrow={true}
            opacity={1}
            place={"left-start"}
          >
            {renderFilterInputs()}
          </Tooltip>
        </div>
      </div>
    );
  };

  function getMarkStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "ISSUED":
        return {
          type: "ISSUED",
          label: t("documents_file_upload.code_status_issued"),
        };
      case "INFLICTED":
        return {
          type: "INFLICTED",
          label: t("documents_file_upload.code_status_inflicted"),
        };
      case "IN_CIRCULATION":
        return {
          type: "IN_CIRCULATION",
          label: t("documents_file_upload.code_status_in_circulation"),
        };
      case "WITHDRAWN_FROM_CIRCULATION":
        return {
          type: "WITHDRAWN_FROM_CIRCULATION",
          label: t("documents_file_upload.code_status_withdrawn"),
        };
      default:
        return { type: "", label: "-" };
    }
  }

  const generateExcelValues = (data: any) => {
    type TransformedObject = {
      [key: string]: any;
    };

    const results =
      data &&
      data?.map((item: any, index: number) => {
        const newObj: TransformedObject = {};

        newObj[`№ ${t("documents_upload.serialNumber")}`] = index + 1;

        for (const [key, value] of Object.entries(item)) {
          if (key === "code") {
            const newKey = t("documents_file_upload.code");
            newObj[newKey] = value;
          }

          if (key === "status") {
            const newKey = t("documents_file_upload.code_status");
            newObj[newKey] = getMarkStatusType(item?.status)?.label || "-";
          }

          if (key === "reason") {
            const newKey = t("documents_upload.checkStatus");
            newObj[newKey] = value;
          }
        }

        return newObj;
      });

    return generateAndDownloadExcel(
      results,
      // `orders-check-${props.documentId}`,
      `${t('CheckResult.FileName')}`,
    );
  };

  const renderResultsTable = () => {
    return (
      <>
        {isResultsLoading ? (
          <div className="w-full text-center flex flex-col justify-center items-center gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.pleaseWaitMessage")}!</span>
              <span>{t("documents_upload.dontClosePageMessage")} !</span>
            </div>
            <div className={style.loader}></div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.checkResult")}</span>
            </div>
            <div>
            {
            (resultsTableData?.values && resultsTableData?.values?.length>0 )
            ?<span className="font-bold text-gray-700 text-[14px]">Всего:{resultsTableData?.values?.length}</span>
            :
            null}
            </div>
            <div className="rounded-lg  overflow-y-scroll overflow-x-scroll bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_upload.serialNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code_status")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.checkStatus")}
                    </th>
                  </tr>
                </thead>
                {resultsTableData?.values?.length === 0 ||
                !tableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={''}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {resultsTableData?.values
                      .slice(
                        resultsPagination.pageIndex *
                          resultsPagination.pageSize,
                        (resultsPagination.pageIndex + 1) *
                          resultsPagination.pageSize,
                      )
                      .map((value: any, i: any) => {
                        const index =
                          resultsPagination.pageIndex *
                            resultsPagination.pageSize +
                          i;
                        return (
                          <tr key={`tr-${index}-${value.id}`}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {index + 1 || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.code || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {getMarkStatusType(value?.status)?.label || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <StatusBadge type="error">
                                {value?.reason || "-"}
                              </StatusBadge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={resultsTableData?.totalItems || 0}
              totalPages={
                resultsTableData?.totalItems
                  ? Math.ceil(
                      resultsTableData?.totalItems / resultsPagination.pageSize,
                    )
                  : 0
              }
              pagination={resultsPagination}
              setPagination={setResultsPagination}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <AlertBox
    isOpen={alertOpen} 
    setIsOpen={setAlertOpen} 
    />
      <ModalComponent
        classNameModal={style.modalWrapper}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderTable()}
        isOpen={props.isOpen}
        onClose={onModalClose}
        footerContent={
          <button
            className={`text-white font-bold py-2 px-4 rounded ${selectedData?.length === 0 || resultsTableData?.values?.length === 0 ? `bg-bg` : `bg-green hover:brightness-75`}`}
            type="button"
            onClick={() => onLoadResults()}
            disabled={
              selectedData?.length === 0 ||
              resultsTableData?.values?.length === 0
            }
          >
            {t("mainButtons3.add")}
          </button>
        }
        cancelText={t("mainButtons.close")}
      />
      <ModalComponent
        classNameModal={style.modalWrapper2}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderResultsTable()}
        isOpen={props.isOpen && resultsModalOpen}
        footerContent={
          isResultsLoading ? (
            <></>
          ) : (
            <button
              className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => generateExcelValues(resultsTableData?.values)}
            >
              {t("documents_upload.donwloadResult")}
            </button>
          )
        }
        cancelText={t("mainButtons.close")}
        onClose={() => {
          onResultsModalClose();
          // onModalClose();
        }}
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => setErrorModal(null)}
        headerContent={
          <h1 className="h__modal">
            {t("documents_file_upload.error_notification")}
          </h1>
        }
        content={
          <div className="error-list">
            <h2> {t("documents_file_upload.errors_list")}:</h2>
            <p>{errorModal}</p>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
    </>
  );
};

export default DocumentsSearch;
